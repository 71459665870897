<div *ngIf="showExpansionButton" class="expand-table" (click)="onToggleTableExpanded.emit(true);">
  <div class="expand-inner">
    <mat-icon *ngIf="isTableExpanded">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="!isTableExpanded">keyboard_arrow_up</mat-icon>
  </div>
</div>
<ng-container *ngIf="!isSelectedClips">
  <cdk-virtual-scroll-viewport #viewport [itemSize]="32" [ngStyle]="{'overflow-x': tableFilterVisible ? 'hidden' : null}">
    <table class="clips-table" [ngClass]="{'dark-mode': isDarkMode, 'clips-on-side': clipsOnSide, 'notes-visible': notesVisible}">
      <ng-container *ngTemplateOutlet="tableHead"></ng-container>
      <tbody>
      <tr class="table-row" *cdkVirtualFor="let clip of tableData; let index = index" (click)="changeToClip(index)" cdkDrag [cdkDragDisabled]="!isDragEnabled"
          [class.clip-playing]="currentVideoIndex == index" [class.clip-disabled]="clip.clipAvailable == false">
        <ng-container *ngTemplateOutlet="clipRow; context: {clip: clip, index: index}"></ng-container>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</ng-container>

<ng-container *ngIf="isSelectedClips">
  <table class="clips-table">
    <ng-container *ngTemplateOutlet="tableHead"></ng-container>
    <tbody cdkDropList (cdkDropListDropped)="dropRow($event)">
    <tr class="table-row" *ngFor="let clip of tableData; let index = index" (click)="changeToClip(index)" cdkDrag [cdkDragDisabled]="!isDragEnabled"
        [class.clip-playing]="currentVideoIndex == index">
      <div *cdkDragPreview class="video-table__clip-preview">
        <div>{{ clip.game ? displayDate(clip.game.date) : clip.gameStartTime | date: 'shortDate' }}</div>
        <entity-avatar class="avatar" *ngIf="!clip.game"
                       [matTooltip]="(clip.homeHasPossession ? clip.homeTeamName : clip.awayTeamName)"
                       [imageUrl]="'https://docs-public.imgix.net/' + (clip.homeHasPossession ? clip.homeTeamImage : clip.awayTeamImage)"
                       [size]="24" lazy></entity-avatar>
        <entity-avatar class="avatar" *ngIf="clip.game" [matTooltip]="clip.game.homeTeam.name"
                       [entity]="clip.game.homeTeam" [size]="24" lazy></entity-avatar>
        <entity-avatar class="avatar" *ngIf="!clip.game"
                       [matTooltip]="(clip.homeHasPossession ? clip.awayTeamName : clip.homeTeamName)"
                       [imageUrl]="'https://docs-public.imgix.net/' + (clip.homeHasPossession ? clip.awayTeamImage : clip.homeTeamImage)"
                       [size]="24" lazy></entity-avatar>
        <entity-avatar class="avatar" *ngIf="clip.game" [matTooltip]="clip.game.awayTeam.name"
                       [entity]="clip.game.awayTeam" [size]="24" lazy></entity-avatar>
        <div>{{ clip.period }}</div>
        <div>{{ (clip.gameClock || clip.startGameclock) * 1000 | date:'mm:ss' }}</div>
      </div>
      <ng-container *ngTemplateOutlet="clipRow; context: {clip: clip, index: index}"></ng-container>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #tableHead>
  <thead>
  <tr class="table-header-row">
    <ng-container *ngFor="let column of columns">
      <th [virtHeader]="column" [top]="inverseTranslation" (onSort)="sortTable($event)">
        <div class="header-container" tabindex="1"
             [ngClass]="{'active': activeFilters.hasOwnProperty(column.matColumnDef)}">
            <span [ngClass]="[determineSortClass(column.matColumnDef)]"
                  *ngIf="column.matColumnDef !== 'select' && column.matColumnDef !== 'sharing'">{{ column.abbrev }}</span>

          <mat-icon *ngIf="column.matColumnDef === 'save'" class="saved">bookmark_border</mat-icon>

          <mat-checkbox [checked]="selectedClips.length == displayedClips.length"
                        *ngIf="column.matColumnDef === 'select'"
                        [indeterminate]="selectedClips.length > 0 && selectedClips.length != displayedClips.length"
                        [class.disabled]="isSaving || isSavingMany"
                        (change)="toggleAllSelected($event)"></mat-checkbox>

          <mat-icon *ngIf="column.matColumnDef === 'sharing'">admin_panel_settings</mat-icon>

          <ng-container *ngIf="column.isFilterable">
            <table-filter [showSlider]="column.showSlider" [defaultMinValue]="column.minValue"
                          [defaultMaxValue]="column.maxValue" [stepSize]="column.stepSize"
                          [showDateFilters]="column.showDateFilters"
                          [values]="column.values" (onFilter)="filterData($event, column.matColumnDef)"
                          [checkboxOptions]="column.checkboxOptions"
                          [showCheckboxes]="!column.showSlider && !column.showDateFilters && !column.showSearch"
                          [useCDKOverlay]="true" (visibilityUpdate)="visibilityUpdate(false)"
                          [visible]="tableFilterVisible" (click)="handleBlur(true, $event)"
                          [displayAsPercent]="column.displayAsPercent" [maxHeight]="clipsOnSide || isTableExpanded ? null : '80px'"
                          [checkboxImages]="column.checkboxImages" [rightOffset]="clipsOnSide ? 300 : null"
                          [displayAsTime]="column.displayAsTime" [isDarkMode]="isDarkMode"
                          [showSearch]="column.showSearch"></table-filter>
          </ng-container>
        </div>
      </th>
    </ng-container>
  </tr>
  </thead>
</ng-template>

<ng-template #clipRow let-clip="clip" let-index="index">
  <ng-container *ngFor="let column of columns">
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'index'">
      <mat-icon *ngIf="currentVideoIndex == index" class="table-icon">play_arrow</mat-icon>
      <div *ngIf="currentVideoIndex != index">{{ index + 1 }}</div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'name'">
      <div class="text">{{ clip.name }}</div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'saved'">
      {{ clip.createDatetime | date: 'shortDate' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'date'">
      {{ clip.game ? displayDate(clip.game.date) : clip.gameStartTime | date: 'shortDate' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'offTeam'">
      <entity-avatar class="avatar" *ngIf="!clip.game"
                     [matTooltip]="(clip.homeHasPossession ? clip.homeTeamName : clip.awayTeamName)"
                     [imageUrl]="'https://docs-public.imgix.net/' + (clip.homeHasPossession ? clip.homeTeamImage : clip.awayTeamImage)"
                     [size]="24" lazy></entity-avatar>
      <entity-avatar class="avatar" *ngIf="clip.game" [matTooltip]="clip.game.homeTeam.name"
                     [entity]="clip.game.homeTeam" [size]="24" lazy></entity-avatar>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'defTeam'">
      <entity-avatar class="avatar" *ngIf="!clip.game"
                     [matTooltip]="(clip.homeHasPossession ? clip.awayTeamName : clip.homeTeamName)"
                     [imageUrl]="'https://docs-public.imgix.net/' + (clip.homeHasPossession ? clip.awayTeamImage : clip.homeTeamImage)"
                     [size]="24" lazy></entity-avatar>
      <entity-avatar class="avatar" *ngIf="clip.game" [matTooltip]="clip.game.awayTeam.name"
                     [entity]="clip.game.awayTeam" [size]="24" lazy></entity-avatar>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'period'">
      {{ clip.period }}
    </td>
    <td [virtCell]="column.matColumnDef"
        *ngIf="column.matColumnDef === 'gameClock' || column.matColumnDef === 'startGameclock'">
      {{ clip[column.matColumnDef] * 1000 | date:'mm:ss' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'description'">
      <div class="text">{{ clip.description }}</div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'shooter-name'">
      <div class="player-container"
           *ngIf="clip.league != 'NCAA' && clip.league != 'INTERNATIONAL' && clip.nbaShooterID && clip.nbaEntityMap && clip.nbaEntityMap[clip.nbaShooterID]">
        <entity-avatar *ngIf="clip.nbaEntityMap[clip.nbaShooterID]?.imageUrl"
                       [entity]="clip.nbaEntityMap[clip.nbaShooterID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.nbaEntityMap[clip.nbaShooterID]?.name"
              class="player-name">{{ clip.nbaEntityMap[clip.nbaShooterID]?.name }}</span>
      </div>
      <div class="player-container"
           *ngIf="(clip.league == 'NCAA' || clip.league == 'INTERNATIONAL') && clip.synergyShooterID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyShooterID]">
        <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyShooterID]?.imageUrl"
                       [entity]="clip.synergyEntityMap[clip.synergyShooterID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.synergyEntityMap[clip.synergyShooterID]?.name"
              class="player-name">{{ clip.synergyEntityMap[clip.synergyShooterID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'assister-name'">
      <div class="player-container"
           *ngIf="clip.league != 'NCAA' && clip.league != 'INTERNATIONAL' && clip.nbaAssisterID && clip.nbaEntityMap && clip.nbaEntityMap[clip.nbaAssisterID]">
        <entity-avatar *ngIf="clip.nbaEntityMap[clip.nbaAssisterID]?.imageUrl"
                       [entity]="clip.nbaEntityMap[clip.nbaAssisterID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.nbaEntityMap[clip.nbaAssisterID]?.name"
              class="player-name">{{ clip.nbaEntityMap[clip.nbaAssisterID]?.name }}</span>
      </div>
      <div class="player-container"
           *ngIf="(clip.league == 'NCAA' || clip.league == 'INTERNATIONAL') && clip.synergyAssisterID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyAssisterID]">
        <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyAssisterID]?.imageUrl"
                       [entity]="clip.synergyEntityMap[clip.synergyAssisterID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.synergyEntityMap[clip.synergyAssisterID]?.name"
              class="player-name">{{ clip.synergyEntityMap[clip.synergyAssisterID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'potential-assister-name'">
      <div class="player-container"
           *ngIf="(clip.league != 'NCAA' && clip.league != 'INTERNATIONAL' && clip.nbaEntityMap && !clip.nbaAssisterID)">
        <entity-avatar *ngIf="clip.nbaEntityMap[clip.nbaPotentialAssisterID]?.imageUrl"
                       [entity]="clip.nbaEntityMap[clip.nbaPotentialAssisterID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.nbaEntityMap[clip.nbaPotentialAssisterID]?.name"
              class="player-name">{{ clip.nbaEntityMap[clip.nbaPotentialAssisterID]?.name }}</span>
      </div>
    </td>    
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'rebounder-name'">
      <div class="player-container"
           *ngIf="clip.league != 'NCAA' && clip.league != 'INTERNATIONAL' && clip.nbaRebounderID && clip.nbaEntityMap && clip.nbaEntityMap[clip.nbaRebounderID]">
        <entity-avatar *ngIf="clip.nbaEntityMap[clip.nbaRebounderID]?.imageUrl"
                       [entity]="clip.nbaEntityMap[clip.nbaRebounderID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.nbaEntityMap[clip.nbaRebounderID]?.name"
              class="player-name">{{ clip.nbaEntityMap[clip.nbaRebounderID]?.name }}</span>
      </div>
      <div class="player-container"
           *ngIf="(clip.league == 'NCAA' || clip.league == 'INTERNATIONAL') && clip.synergyRebounderID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyRebounderID]">
        <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyRebounderID]?.imageUrl"
                       [entity]="clip.synergyEntityMap[clip.synergyRebounderID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.synergyEntityMap[clip.synergyRebounderID]?.name"
              class="player-name">{{ clip.synergyEntityMap[clip.synergyRebounderID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'blocker-name'">
      <div class="player-container"
           *ngIf="clip.league != 'NCAA' && clip.league != 'INTERNATIONAL' && clip.nbaBlockerID && clip.nbaEntityMap && clip.nbaEntityMap[clip.nbaBlockerID]">
        <entity-avatar *ngIf="clip.nbaEntityMap[clip.nbaBlockerID]?.imageUrl"
                       [entity]="clip.nbaEntityMap[clip.nbaBlockerID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.nbaEntityMap[clip.nbaBlockerID]?.name"
              class="player-name">{{ clip.nbaEntityMap[clip.nbaBlockerID]?.name }}</span>
      </div>
      <div class="player-container"
           *ngIf="(clip.league == 'NCAA' || clip.league == 'INTERNATIONAL') && clip.synergyBlockerID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyBlockerID]">
        <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyBlockerID]?.imageUrl"
                       [entity]="clip.synergyEntityMap[clip.synergyBlockerID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.synergyEntityMap[clip.synergyBlockerID]?.name"
              class="player-name">{{ clip.synergyEntityMap[clip.synergyBlockerID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'cutter-name'">
    <div class="player-container"
        *ngIf="(clip.league == 'NCAA') && clip.synergyCutterID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyCutterID]">
      <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyCutterID]?.imageUrl"
                    [entity]="clip.synergyEntityMap[clip.synergyCutterID]" [size]="24"
                    [maskCorners]="true" lazy></entity-avatar>
      <span *ngIf="clip.synergyEntityMap[clip.synergyCutterID]?.name"
          class="player-name">{{ clip.synergyEntityMap[clip.synergyCutterID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'ballhandler-name'">
    <div class="player-container"
        *ngIf="(clip.league == 'NCAA') && clip.synergyBallHandlerID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyBallHandlerID]">
      <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyBallHandlerID]?.imageUrl"
                    [entity]="clip.synergyEntityMap[clip.synergyBallHandlerID]" [size]="24"
                    [maskCorners]="true" lazy></entity-avatar>
      <span *ngIf="clip.synergyEntityMap[clip.synergyBallHandlerID]?.name"
          class="player-name">{{ clip.synergyEntityMap[clip.synergyBallHandlerID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'screener-name'">
      <div class="player-container"
          *ngIf="(clip.league == 'NCAA') && clip.synergyScreenerID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyScreenerID]">
        <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyScreenerID]?.imageUrl"
                      [entity]="clip.synergyEntityMap[clip.synergyScreenerID]" [size]="24"
                      [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.synergyEntityMap[clip.synergyScreenerID]?.name"
            class="player-name">{{ clip.synergyEntityMap[clip.synergyScreenerID]?.name }}</span>
        </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'passer-name'">
      <div class="player-container"
        *ngIf="(clip.league == 'NCAA') && clip.synergyPasserID && clip.synergyEntityMap && clip.synergyEntityMap[clip.synergyPasserID]">
      <entity-avatar *ngIf="clip.synergyEntityMap[clip.synergyPasserID]?.imageUrl"
                    [entity]="clip.synergyEntityMap[clip.synergyPasserID]" [size]="24"
                    [maskCorners]="true" lazy></entity-avatar>
      <span *ngIf="clip.synergyEntityMap[clip.synergyPasserID]?.name"
          class="player-name">{{ clip.synergyEntityMap[clip.synergyPasserID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'shotClock'">
      {{ clip.shotClock | number:'1.0-1' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'shotZoneIntermediate'">
      {{ clip.shotZoneIntermediate }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'gameState'">
      <span class="gamestate-container">{{ clip.gameState }}</span>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'actionName'">
      <span *ngIf="clip.league == 'NCAA'" class="action-container">{{ getActionDescription(clip.actionName) }}</span>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'contestednessNumber'">
      {{ clip.contestedness }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'setup'">
      {{ clip.setup }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'shotDistance'">
      {{ clip.shotDistance | number: '1.1-1' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'qefg'"
        [ngClass]="clip.qefg ? 'background-percentile-text-' + roundNumber(100*clip.qefg) : 'null-cell'">
      {{ clip.qefg | number: '1.3-3' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'xefg'"
        [ngClass]="clip.xefg ? 'background-percentile-text-' + roundNumber(100*clip.xefg) : 'null-cell'">
      {{ clip.xefg | number: '1.3-3' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'shotType'">
      {{ clip.shotType }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'foulDrawn'">
      {{ clip.foulDrawn ? 'X' : '' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'isMake'"
        [ngClass]="clip.isMake ? 'make' : 'miss'">
      {{ clip.isMake ? 'Make' : 'Miss' }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'player-name'">
      <div class="player-container"
           *ngIf="clip.league != 'NCAA' && clip.league != 'INTERNATIONAL' && clip.chartingPlayerNBAID && clip.nbaEntityMap && clip.nbaEntityMap[clip.chartingPlayerNBAID]">
        <entity-avatar *ngIf="clip.nbaEntityMap[clip.chartingPlayerNBAID]?.imageUrl"
                       [entity]="clip.nbaEntityMap[clip.chartingPlayerNBAID]" [size]="24"
                       [maskCorners]="true" lazy></entity-avatar>
        <span *ngIf="clip.nbaEntityMap[clip.chartingPlayerNBAID]?.name"
              class="player-name">{{ clip.nbaEntityMap[clip.chartingPlayerNBAID]?.name }}</span>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'chartingCategory'">
      {{ clip.chartingCategory }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'chartingResult'">
      {{ clip.chartingResult }}
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'playlists'">
      <div class="text">
        <ng-container *ngFor="let playlist of clip.playlists; let lastPlaylist = last">
          {{ playlist.name }}
          <ng-container *ngIf="!lastPlaylist">,</ng-container>
        </ng-container>
      </div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'players'">
      <entity-avatar *ngFor="let player of clip.onCourtEntities" class="avatar" [entity]="player" [size]="28"
                     [fitClamp]="true" [matTooltip]="player.name" lazy></entity-avatar>
    </td>
    <!--<td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'taggedPlayers'">
      <div class="players-container">
        <entity-avatar *ngFor="let player of clip.playersTagged" class="avatar" [entity]="player" [size]="28"
                       [fitClamp]="true" [matTooltip]="player.name" lazy></entity-avatar>
      </div>
    </td>>-->
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'styleOfPlayTag'">
      <div class="text">{{ getStyleOfPlayTag(clip) }}</div>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'emptySpacing'"></td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'isUploaded'">
      <mat-icon *ngIf="clip.videoSource == 'UPLOAD'" matTooltip="User Uploaded Clip">backup</mat-icon>
    </td>

    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'label'">
      <div cdkOverlayOrigin #labelButtonTrigger="cdkOverlayOrigin" (click)="clip.author?.userID == user?.id && editRowLabel(clip, $event)">
        <div *ngIf="clip.label && clip.author?.userID == user?.id" class="label-indicator" [ngClass]="clip.label">
          <mat-icon class="label-edit">edit</mat-icon>
        </div>
        <div *ngIf="!clip.label && clip.author?.userID == user?.id" class="label-input">
          <mat-icon svgIcon="bild-highlight"></mat-icon>
        </div>
      </div>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="labelButtonTrigger"
        [cdkConnectedOverlayOpen]="clip.isOverlayVisible"
        [cdkConnectedOverlayPositions]="positions">
        <div class="video-clip-table__label-container" tabindex="1" #labelContainer (blur)="clip.isOverlayVisible = false">
          <div class="color-option" style="background-color: #13c16d" (click)="updateClipLabel(clip, 'Green')"></div>
          <div class="color-option" style="background-color: #e27061" (click)="updateClipLabel(clip, 'Red')"></div>
          <div class="color-option" style="background-color: #ecea17" (click)="updateClipLabel(clip, 'Yellow')"></div>
          <div class="color-option" style="background-color: #9acce3" (click)="updateClipLabel(clip, 'Blue')"></div>
          <mat-icon style="cursor: pointer;" *ngIf="clip.label" (click)="updateClipLabel(clip, null)">delete</mat-icon>
        </div>
      </ng-template>
    </td>

    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'sharing'">
      <mat-icon *ngIf="clip.author?.userID == user?.id && clip.sharedUsers?.length == 0">lock</mat-icon>
      <mat-icon *ngIf="clip.author?.userID == user?.id && clip.sharedUsers?.length > 0">group</mat-icon>
      <entity-avatar *ngIf="clip.author?.userID != user?.id" class="avatar" [entity]="clip.author" [size]="24" [fitClamp]="true" lazy></entity-avatar>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'notes'" (click)="$event.stopPropagation()">
      <input *ngIf="clip.author?.userID == user?.id" matInput type="text" [(ngModel)]="clip.notes" (ngModelChange)="onNotesChanged(clip)">
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'save'" (click)="$event.stopPropagation()">
      <button mat-icon-button *ngIf="!isClipSaved(clip)" (click)="changeToClipAndSave(index)">
        <mat-icon class="unsaved">bookmark_border</mat-icon>
      </button>
      <mat-icon *ngIf="isClipSaved(clip)" class="saved">bookmark</mat-icon>
    </td>
    <td [virtCell]="column.matColumnDef" *ngIf="column.matColumnDef === 'select'"
        (click)="$event.stopPropagation()">
      <mat-checkbox [(ngModel)]="clip.isSelected" [class.disabled]="isSaving || isSavingMany"
                    (change)="updateSelectedClips(clip)"></mat-checkbox>
    </td>
  </ng-container>
</ng-template>
