import * as moment from 'moment';
import * as _ from 'lodash';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {debounceTime, take} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {Title} from '@angular/platform-browser';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntitiesService} from '@services/entities.service';
import {VideoService} from '@services/video.service';
import {MatDialog} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {AutocompleteService} from 'apps/_services/autocomplete.service';
import {User} from '@models/users';
import {AuthService} from '@services/auth.service';
import {League} from '@models/leagues';
import {
  defOffPersistingFilters,
  persistingFilters,
  videoActionFilterOptions,
  videoEventFilterOptions,
} from '@models/constants/video/video-metric-types';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {MatLegacyAutocomplete as MatAutocomplete} from '@angular/material/legacy-autocomplete';
import {MatLegacySelectChange} from '@angular/material/legacy-select';
import {APPS_CONFIG} from '../../../apps-config.constants';
import {IAppsConfig} from '../../../apps-config.interface';
import {
  abstractActionItem,
  allActionItems,
  gamesActionItem,
  possessionsAndChancesItem,
  DefOffPossessionsAndChancesItem,
  teamsActionItem,
  timeActionItem,
  VideoPlayerFields,
  VideoTables,
  VideoDefenseTags,
  VideoOffenseTags,
  SpecialDefenseTags,
  BooleanDefenseTags,
  defenseItems,
  offenseItems,
} from '@models/constants/video/video-action-items';
import {DOMHelper} from '@helpers/dom.helper';
import {
  actionNames,
  VideoSubject,
  videoSubjectToColumnName,
  videoSubjectToLabel,
} from '@models/constants/video/video-subjects';
import { Video } from '@models/videos';


@UntilDestroy()
@Component({
  selector: 'bild-generate-playlist',
  templateUrl: './generate-playlist.component.html',
  styleUrls: ['./generate-playlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  })
export class GeneratePlaylistComponent implements OnInit, OnDestroy {
  @Input() entityNBA: League = null;
  @Input() entityGLG: League = null;
  @Input() entityNCAA: League = null;
  @Input() userGeneratedPlaylists;
  @Input() set displayedFilters(val) {
    this._displayedFilters = _.cloneDeep(val);
  }
  get displayedFilters() {
    return this._displayedFilters;
  }
  @Input() set filtersData(val) {
    this._filtersData = _.cloneDeep(val);
  }
  get filtersData() {
    return this._filtersData;
  }
  @ViewChild('abstractEntityAutocomplete', { static: true }) abstractEntityAutocomplete: MatAutocomplete;
  @ViewChild('primaryEntityAutocomplete', { static: true }) primaryEntityAutocomplete: MatAutocomplete;
  @ViewChild('offCourtEntitiesInput') offCourtEntitiesInput: ElementRef;
  @ViewChild('onCourtEntitiesInput') onCourtEntitiesInput: ElementRef;
  @ViewChild('offensiveLineupEntitiesInput') offensiveLineupEntitiesInput: ElementRef;
  @ViewChild('defensiveLineupEntitiesInput') defensiveLineupEntitiesInput: ElementRef;
  @ViewChild('primaryDefensivePlayerInput') primaryDefensivePlayerInput: ElementRef;
  @ViewChild('abstractEntityInput') abstractEntityInput: ElementRef;
  @ViewChild('primaryEntityInput') primaryEntityInput: ElementRef;
  @ViewChild('secondaryEntityInput') secondaryEntityInput: ElementRef;
  @ViewChild('teamEntitiesInput') teamEntitiesInput: ElementRef;
  @ViewChild('defensiveTeamEntitiesInput') defensiveTeamEntitiesInput: ElementRef;
  @ViewChild('offensiveTeamEntitiesInput') offensiveTeamEntitiesInput: ElementRef;

  _displayedFilters;
  _filtersData;

  readonly abstractActionItem = abstractActionItem;
  readonly gamesActionItem = gamesActionItem;
  readonly maxDate= moment().format('YYYY-MM-DD');
  readonly minDate= '1996-01-01';
  readonly minDistance= 0;
  readonly maxDistance= 30;
  readonly maxGameClock = 720;
  readonly minGameClock = 0;
  readonly maxShotClock= 24;
  readonly minDribbles = 0;
  readonly maxDribbles= 24;
  readonly minDuration = 0;
  readonly maxDuration= 24;
  readonly minShotClock= 0;
  readonly possessionsAndChancesItem = possessionsAndChancesItem;
  readonly DefOffPossessionsAndChancesItem = DefOffPossessionsAndChancesItem;
  readonly defenseItems = defenseItems;
  readonly offenseItems = offenseItems;
  readonly teamsActionItem = teamsActionItem;
  readonly timeActionItem = timeActionItem;
  readonly videoActionFilterOptions = videoActionFilterOptions;
  readonly videoEventFilterOptions = videoEventFilterOptions;
  readonly VideoSubject = VideoSubject;
  readonly VideoTables = VideoTables;
  readonly VideoPlayerFields = VideoPlayerFields;
  readonly VideoDefenseTags = VideoDefenseTags;
  readonly VideoOffenseTags = VideoOffenseTags;
  readonly SpecialDefenseTags = SpecialDefenseTags;
  readonly BooleanDefenseTags = BooleanDefenseTags;
  readonly defaultFilters = [
    {
      'table': VideoTables.CHANCE_DETAILS,
      'name': 'league_id',
      'not': false,
      'args': [this.config.NCAA_ID],
    },
    {
      'table': VideoTables.CHANCE_DETAILS,
      'name': 'season',
      'not': false,
      'args': [2023],
    },
  ];

  readonly defaultDisplayedFilters = {
    actions: [],
    leagueIDs: [101],
    onCourtEntities: [],
    offCourtEntities: [],
    offensiveLineupEntities: [],
    defensiveLineupEntities: [],
    primaryDefensivePlayer: [],
    teamEntities: [],
    defensiveTeamEntities: [],
    offensiveTeamEntities: [],
    ctgGames: [],
    seasons: [2023],
    minDistance: 0,
    maxDistance: 30,
    minGameclock: 0,
    maxGameclock: 720,
    minShotclock: 0,
    maxShotclock: 24,
    minQEFG: 0,
    maxQEFG: 1,
    minXEFG: 0,
    maxXEFG: 1,
  };

  positionMap = {
    'nba_player_ids_expected_defending_1' : 1,
    'nba_player_ids_expected_defending_2': 2,
    'nba_player_ids_expected_defending_3' : 3,
    'nba_player_ids_expected_defending_4' : 4,
    'nba_player_ids_expected_defending_5' : 5
  };

  lineupMap = {
    "ANY" : 0,
    "PG" : 1,
    "SG" : 2,
    "SF" : 3,
    "PF" : 4,
    "C" : 5
  }

  reverseLineupMap = {
    0 : "ANY",
    1 : "PG",
    2 : "SG",
    3 : "SF",
    4 : "PF",
    5 : "C"
  }

  crossmatchedMap = {
    'nba_player_ids_defending_ml' : 'nba_player_ids_crossmatched_defending_ml',
    'nba_player_ids_defending_ro' : 'nba_player_ids_crossmatched_defending_ro',
    'nba_player_ids_defending_re' : 'nba_player_ids_crossmatched_defending_re',
    'nba_player_ids_defending_kr' : 'nba_player_ids_crossmatched_defending_kr',
    'nba_player_ids_defending_s' : 'nba_player_ids_crossmatched_defending_s',
    'nba_player_ids_defending_ts' : 'nba_player_ids_crossmatched_defending_ts',
    'nba_player_ids_defending_c' : 'nba_player_ids_crossmatched_defending_c',
    'nba_player_ids_defending_f' : 'nba_player_ids_crossmatched_defending_f',
  }

  reverseCrossmatchedMap = {
    'nba_player_ids_crossmatched_defending_ml': 'nba_player_ids_defending_ml',
    'nba_player_ids_crossmatched_defending_ro': 'nba_player_ids_defending_ro',
    'nba_player_ids_crossmatched_defending_re': 'nba_player_ids_defending_re',
    'nba_player_ids_crossmatched_defending_kr': 'nba_player_ids_defending_kr',
    'nba_player_ids_crossmatched_defending_s': 'nba_player_ids_defending_s',
    'nba_player_ids_crossmatched_defending_ts': 'nba_player_ids_defending_ts',
    'nba_player_ids_crossmatched_defending_c': 'nba_player_ids_defending_c',
    'nba_player_ids_crossmatched_defending_f': 'nba_player_ids_defending_f',
  };
  
  
  clips: any = [];
  gameOptions: any[];
  isMobile: boolean;
  isInvalid: boolean = false;
  isPristine: boolean = true;
  posDupe: boolean = false;
  selectedLeague: string;
  user: User;
  isRecentlyGeneratedExpanded: boolean = false;

  filteredUsers: any[];
  viewersControl: FormControl = new FormControl();
  filteredEntities: any[];
  entitiesControl: FormControl = new FormControl();
  filteredOnCourtEntities: any[];
  onCourtEntitiesControl: FormControl = new FormControl();
  filteredOffCourtEntities: any[];
  offCourtEntitiesControl: FormControl = new FormControl();
  filteredOffensiveLineupEntities: any[];
  offensiveLineupEntitiesControl: FormControl = new FormControl();
  filteredDefensiveLineupEntities: any[];
  defensiveLineupEntitiesControl: FormControl = new FormControl();
  filteredPrimaryDefensivePlayers: any[];
  primaryDefensivePlayerControl: FormControl = new FormControl();
  filteredAbstractEntities: any[];
  filteredPrimaryEntities: any[];
  abstractEntityControl: FormControl = new FormControl();
  primaryEntityControl: FormControl = new FormControl();
  filteredSecondaryEntities: any[];
  secondaryEntityControl: FormControl = new FormControl();
  filteredTeamEntities: any[];
  filteredDefensiveTeamEntities: any[];
  filteredOffensiveTeamEntities: any[];
  teamEntitiesControl: FormControl = new FormControl();
  defensiveTeamEntitiesControl: FormControl = new FormControl();
  offensiveTeamEntitiesControl: FormControl = new FormControl();
  gameSearchFilterValue: string = '';
  filteredGameOptions: any[] = [];
  positions: string[] = ['ANY', 'PG', 'SG', 'SF', 'PF', 'C'];

  selectedFilterOption;

  seasonOptions: number[] = [2022, 2023, 2024];
  periodOptions: number[] = [1, 2, 3, 4, 5, 6, 7];

  ncaaGameclockSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 1200,
    step: 1,
    noSwitching: true,
    animate: false,
    translate: (value: number): string => {
      return Math.floor(value/60) + ':' + ((value % 60) < 10 ? ('0' + (value % 60)) : (value % 60));
    },
  };

  proGameclockSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 720,
    step: 1,
    noSwitching: true,
    animate: false,
    translate: (value: number): string => {
      return Math.floor(value/60) + ':' + ((value % 60) < 10 ? ('0' + (value % 60)) : (value % 60));
    },
  };

  distanceOptions = {
    showTicks: false,
    floor: 0,
    ceil: 30,
    step: 1,
    noSwitching: true,
    animate: false,
  };

  qEfgOptions = {
    showTicks: false,
    floor: 0,
    ceil: 1,
    step: 0.001,
    noSwitching: true,
    animate: false,
  };

  xEfgOptions = {
    showTicks: false,
    floor: 0,
    ceil: 1,
    step: 0.001,
    noSwitching: true,
    animate: false,
  };

  ncaaShotclockSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 30,
    step: 1,
    noSwitching: true,
    animate: false,
  };

  proShotclockSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 24,
    step: 1,
    noSwitching: true,
    animate: false,
  };

  touchDribblesSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 40,
    step: 1,
    noSwitching: true,
    animate: false,
  };

  touchDurationSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 40,
    step: 1,
    noSwitching: true,
    animate: false,
  };

  @Output() generatedPlaylistParameters = new EventEmitter<any>();

  get isNCAA() {
    return ['NCAA', 'OTHER'].includes(this.selectedLeague);
  }

  get selectedActionOptions() {
    const selectedActionsFilters = _.filter(this.filtersData.filters, (filt) => filt.table === VideoTables.ACTION && filt.name === 'name');
    const selectedActionNames = _.map(selectedActionsFilters, 'group');
    return _.filter(videoActionFilterOptions, (filt) => selectedActionNames.includes(filt.value));
  }


  constructor(
    @Inject(APPS_CONFIG) public config: IAppsConfig,
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected domHelper: DOMHelper,
    protected entitiesService: EntitiesService,
    protected matDialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected title: Title,
    protected videoService: VideoService,
  ) { }
  ngOnInit() {
    this.title.setTitle('Explore Video');

    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.authService.currentUserData.pipe(take(1), untilDestroyed(this)).subscribe((user: User) => {
      this.user = user;
    });

    this.selectedFilterOption = _.find(videoEventFilterOptions, ['value', this.filtersData.subject]);

    if (this.videoService.activeFilterSubject.value) {
      this.openActiveFilter(this.videoService.activeFilterSubject.value);
    }
    this.videoService.activeFilterSubject.pipe(untilDestroyed(this)).subscribe((activeFilter: string) => {
      this.openActiveFilter(activeFilter);
    });

    const leagueFilter = _.find(this.filtersData.filters, ['name', 'league_id']);

    if (leagueFilter?.args?.includes(1)) {
      this.selectedLeague = 'NBA';
      this.displayedFilters.leagueIDs = [1];
    } else if (leagueFilter?.args?.includes(2)) {
      this.selectedLeague = 'GLG';
      this.displayedFilters.leagueIDs = [2];
    } else if (leagueFilter?.args?.includes(101)) {
      this.selectedLeague = 'NCAA';
      this.displayedFilters.leagueIDs = [101];
    } else {
      this.selectedLeague = 'OTHER';
      this.displayedFilters.leagueIDs = [];
    }

    if (!this.gameOptions?.length) {
      this.updateGameOptions();
    }

    this.viewersControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterUsers(q);
            },
        );

    this.entitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterEntities(q);
            },
        );

    this.onCourtEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterOnCourtEntities(q);
            },
        );

    this.offCourtEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterOffCourtEntities(q);
            },
        );

    this.offensiveLineupEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterOffensiveLineupEntities(q);
            },
        );

    this.defensiveLineupEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterDefensiveLineupEntities(q);
            },
        );

    this.primaryDefensivePlayerControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterPrimaryDefensivePlayers(q);
            },
        );

    this.abstractEntityControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterAbstractEntities(q);
            },
        );

    this.primaryEntityControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterPrimaryEntities(q);
            },
        );

    this.secondaryEntityControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterSecondaryEntities(q);
            },
        );

    this.teamEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterTeamEntities(q);
            },
        );

    this.defensiveTeamEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterDefensiveTeamEntities(q);
            },
        );

    this.offensiveTeamEntitiesControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterOffensiveTeamEntities(q);
            },
        );
  }

  convertEntityToNBAIDs(entityIDs) {
    return this.videoService.entityNBALookup.filter((mapping) => entityIDs.includes(mapping[0])).map((mapping) => mapping[1]);
  }

  convertEntityToSynergyIDs(entityIDs) {
    return this.videoService.entitySynergyLookup.filter((mapping) => entityIDs.includes(mapping[0])).map((mapping) => mapping[1]);
  }

  convertSportradarToSynergyIDs(sportradarIDs) {
    const playerEntityIDs = this.videoService.entitySportradarLookup.filter((mapping) => sportradarIDs.includes(mapping[1])).map((mapping) => mapping[0]);
    return this.convertEntityToSynergyIDs(playerEntityIDs);
  }

  openActiveFilter(activeFilter: string) {
    if (!activeFilter) {
      return;
    }

    allActionItems.forEach((actionItem: any) => {
      actionItem.actionItems.forEach((item) => {
        item.options.forEach((option) => {
          if (option.filterName === activeFilter) {
            this.selectedFilterOption.selectedAction = actionItem;
            this.selectedFilterOption.selectedAction.selectedSubAction = option;
            this.cdr.markForCheck();
          }
        });
      });
    });
  }

  updateMinMaxFilter(fieldName: string, minValue: any, maxValue: any, tableName = VideoTables.CHANCE_DETAILS) {
    const minMaxFilter = _.find(this.filtersData.filters, ['name', fieldName]);
    if (minMaxFilter == null) {
      this.filtersData.filters.push({
        table: tableName,
        name: fieldName,
        not: false,
        args: {
          'min': minValue,
          'max': maxValue,
        },
      });
    } else {
      minMaxFilter.args['min'] = minValue;
      minMaxFilter.args['max'] = maxValue;
    }
    this.updateFiltersReference();
  }

  updateDate() {
    let minValue = null;
    let maxValue = null;
    if (this.displayedFilters.minDate || this.displayedFilters.maxDate) {
      minValue = this.displayedFilters.minDate ? this.displayedFilters.minDate.format('YYYY-MM-DD') : this.minDate;
      maxValue = this.displayedFilters.maxDate ? this.displayedFilters.maxDate.format('YYYY-MM-DD') : this.maxDate;
      this.updateMinMaxFilter('game_date', minValue, maxValue);
    } else {
      this.displayedFilters.minDate = minValue;
      this.displayedFilters.maxDate = minValue;
      this.displayedFilters = _.cloneDeep(this.displayedFilters);
      this.filtersData.filters = this.filtersData.filters.filter((filt) => filt.name != 'game_date');
    }
    this.updateGameOptions();
  }

  updateGameOptions(): void {
    // Reset game options so old games don't display while new games are loading
    this.gameOptions = [];
    this.cdr.markForCheck();

    const minDate = this.displayedFilters?.minDate ? this.displayedFilters.minDate.format('YYYY-MM-DD') : undefined;
    const maxDate = this.displayedFilters?.maxDate ? this.displayedFilters.maxDate.format('YYYY-MM-DD') : undefined;
    const teamEntityIDs = this.displayedFilters.teamEntities.map((entity) => this.convertEntityIDs(entity));
    const defensiveTeamEntities = this.displayedFilters.defensiveTeamEntities.map((entity) => this.convertEntityIDs(entity));
    const offensiveTeamEntities = this.displayedFilters.offensiveTeamEntities.map((entity) => this.convertEntityIDs(entity));
    const onCourtEntities = this.displayedFilters.onCourtEntities.map((entity) => this.convertEntityIDs(entity));
    const offCourtEntities = this.displayedFilters.offCourtEntities.map((entity) => this.convertEntityIDs(entity));
    const offensiveLineupEntities = this.displayedFilters.offensiveLineupEntities.map((entity) => this.convertEntityIDs(entity));
    const defensiveLineupEntities = this.displayedFilters.defensiveLineupEntities.map((entity) => this.convertEntityIDs(entity));
    const primaryDefensivePlayer = [];
    if (this.displayedFilters.primaryDefensivePlayer) {
      primaryDefensivePlayer.push(this.convertEntityIDs(this.displayedFilters.primaryDefensivePlayer));
    }
    const primaryEntity = [];
    if (this.displayedFilters.primaryEntity) {
      primaryEntity.push(this.convertEntityIDs(this.displayedFilters.primaryEntity));
    }
    const secondaryEntity = [];
    if (this.displayedFilters.secondaryEntity) {
      secondaryEntity.push(this.convertEntityIDs(this.displayedFilters.secondaryEntity));
    }
    const abstractEntity = [];
    if (this.displayedFilters.abstractEntity) {
     abstractEntity.push(this.convertEntityIDs(this.displayedFilters.abstractEntity));
    }
    this.videoService.getAvailableGames(
        this.displayedFilters?.leagueIDs, this.displayedFilters?.seasons, this.displayedFilters?.game_type,
        minDate, maxDate, teamEntityIDs, defensiveTeamEntities, offensiveTeamEntities, onCourtEntities, offCourtEntities,
        offensiveLineupEntities, defensiveLineupEntities, primaryDefensivePlayer, primaryEntity, secondaryEntity, abstractEntity).pipe(untilDestroyed(this)).subscribe(
        (availableGames) => {
          this.gameOptions = availableGames;
          this.filterGameOptions();
          this.cdr.markForCheck();
        },
        (error) => {
          console.log(error);
        },
    );
  }

  filterGameOptions(): void {
    this.filteredGameOptions = this.gameOptions.filter(gameOption => this.gamePassesSearchFilter(gameOption)).slice(0, 100);
  }

  filterUsers(q: string): void {
    this.filteredUsers = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                // TODO
                // this.filteredUsers = entities.filter((entity) => (entity.id != this.user?.entity?.id)) => sharedUser.id == entity.id) == -1));
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterEntities(q: string): void {
    this.filteredEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                // TODO
                // this.filteredEntities = entities.filter((entity) => (entity.id != this.user?.entity?.id) &&
              // (this.currentClip.additionalTaggedEntities.findIndex((taggedEntity) => taggedEntity.id == entity.id) == -1) &&
              // (this.currentClip.onCourtEntities.findIndex((onCourtEntity) => onCourtEntity.id == entity.id) == -1));
              },
          );
    }
  }

  filterOnCourtEntities(q: string): void {
    this.filteredOnCourtEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredOnCourtEntities = entities.filter((entity) => entity.subtype?.name == 'Player' &&
              this.displayedFilters.onCourtEntities.findIndex((onCourtEntity) => onCourtEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterOffCourtEntities(q: string): void {
    this.filteredOffCourtEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredOffCourtEntities = entities.filter((entity) => entity.subtype?.name == 'Player' &&
              this.displayedFilters.offCourtEntities.findIndex((offCourtEntity) => offCourtEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterOffensiveLineupEntities(q: string): void {
    this.filteredOffensiveLineupEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredOffensiveLineupEntities = entities.filter((entity) => entity.subtype?.name == 'Player' &&
              this.displayedFilters.offensiveLineupEntities.findIndex((offensiveLineupEntity) => offensiveLineupEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterDefensiveLineupEntities(q: string): void {
    this.filteredDefensiveLineupEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredDefensiveLineupEntities = entities.filter((entity) => entity.subtype?.name == 'Player' &&
              this.displayedFilters.defensiveLineupEntities.findIndex((defensiveLineupEntity) => defensiveLineupEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterPrimaryDefensivePlayers(q: string): void {
    this.filteredPrimaryDefensivePlayers = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredPrimaryDefensivePlayers = entities.filter((entity) => entity.subtype?.name == 'Player');
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterAbstractEntities(q: string): void {
    this.filteredAbstractEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredAbstractEntities = entities.filter((entity) => entity.subtype?.name == 'Player');

                this.cdr.markForCheck();
              },
          );
    }
  }

  filterPrimaryEntities(q: string): void {
    this.filteredPrimaryEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredPrimaryEntities = entities.filter((entity) => entity.subtype?.name == 'Player');
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterSecondaryEntities(q: string): void {
    this.filteredSecondaryEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredSecondaryEntities = entities.filter((entity) => entity.subtype?.name == 'Player');
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterTeamEntities(q: string): void {
    this.filteredTeamEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredTeamEntities = entities.filter((entity) => entity.type == 'Team' &&
              this.displayedFilters.teamEntities.findIndex((teamEntity) => teamEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterDefensiveTeamEntities(q: string): void {
    this.filteredDefensiveTeamEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredDefensiveTeamEntities = entities.filter((entity) => entity.type == 'Team' &&
              this.displayedFilters.defensiveTeamEntities.findIndex((teamEntity) => teamEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  filterOffensiveTeamEntities(q: string): void {
    this.filteredOffensiveTeamEntities = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredOffensiveTeamEntities = entities.filter((entity) => entity.type == 'Team' &&
              this.displayedFilters.offensiveTeamEntities.findIndex((teamEntity) => teamEntity.id == entity.id) == -1);
                this.cdr.markForCheck();
              },
          );
    }
  }

  updateGameValues() {
    const gameIdentifierColumn = (['NCAA', 'OTHER'].includes(this.selectedLeague) ? 'synergy_' : 'nba_') + 'game_id';
    if (!this.displayedFilters.ctgGames?.length) {
      const gameFilter = _.find(this.filtersData.filters, ['name', gameIdentifierColumn]);
      if (gameFilter) {
        this.filtersData.filters = _.without(this.filtersData.filters, gameFilter);
      }
      return;
    }
    const games = _.map(this.displayedFilters.ctgGames, (['NCAA', 'OTHER'].includes(this.selectedLeague) ? 'synergyGameID' : 'nbaGameID'));
    this.updateFilter(gameIdentifierColumn, games, false, VideoTables.CHANCE_DETAILS, false);
  }

  updateAbstractEntities() {
    this.filtersData.filters = _.filter(this.filtersData.filters, (filt) => {
      return filt.table !== VideoTables.ABSTRACT || !actionNames.includes(filt.group);
    });

    if (!this.displayedFilters.abstractEntity || this.displayedFilters.abstractEntity?.length === 0) {
      return;
    }

    let nbaIDs;

    if (this.displayedFilters.abstractEntity?.length) {
      nbaIDs = this.convertEntityToSynergyIDs(_.map(this.displayedFilters.abstractEntity, 'id'));
    } else {
      nbaIDs = this.convertEntityToSynergyIDs([this.displayedFilters.abstractEntity.id]);
    }

    const columnName = this.addColumnPrefix('subject_player_id');

    this.displayedFilters.actions?.forEach((action) => {
      if (this.displayedFilters.abstractEntity) {
        this.filtersData.filters.push({
          args: nbaIDs,
          name: columnName,
          group: action,
          not: false,
          table: VideoTables.ABSTRACT,
        });
      }
    });
  }

  generatePlaylist() {
    this.checkDefOffValues();
    this.updateGameValues();
    this.updateAbstractEntities();
    const filtersValue = this.isPristine ? null : {filtersData: this.filtersData, displayedFilters: this.displayedFilters};
    this.videoService.activeFilterSubject.next(null);
    this.generatedPlaylistParameters.emit(filtersValue);
  }

  clearToGenerate() {
    const playerFilter = _.find(this.filtersData.filters, ['name', this.selectedFilterOption?.name == 'Defense' ? 'nba_defensive_player_ids' : 'nba_offensive_player_ids']);
    let curPosArr = {
      'nba_defensive_player_ids': new Set<number>(),
      'nba_offensive_player_ids': new Set<number>()
    };
    let foundDuplicate = false;
    this.filtersData.filters.forEach(filt => {
      if (filt?.position) {
        const posId = filt.position;
        if (curPosArr[filt.name] !== undefined) {
          if (curPosArr[filt.name].has(posId)) {
            foundDuplicate = true;
          } else {
            curPosArr[filt.name].add(posId);
          }
        }
      }
    });
    this.posDupe = foundDuplicate;
    return ((this.selectedFilterOption?.name !== 'Defense' && this.selectedFilterOption?.name !== 'Offense') || playerFilter) && !this.posDupe;
  }  

  getTooltip() {
    return this.posDupe ? 'Duplicate position added in lineup' : (this.selectedFilterOption?.name == 'Defense' ? 'Please add a defensive player' : 'Please add an offensive player');
  }

  changeFilterOption(option) {
    const comingFromDef = this.selectedFilterOption?.name == 'Defense';
    const switchingToDef = option.name == 'Defense'
    const comingFromOff = this.selectedFilterOption?.name == 'Offense';
    const switchingToOff = option.name == 'Offense';
    this.selectedFilterOption = option;
    this.resetFilters(false, false, comingFromDef, switchingToDef, comingFromOff, switchingToOff);
    this.filtersData.subject = option.value;
  }

  removeInvalidFilters() {
    const originalLength = this.filtersData.filters.length;
    this.filtersData.filters = _.filter(this.filtersData.filters, (filter) => {
      return filter.args?.length != 0;
    });
    const isInvalid = originalLength != this.filtersData.filters.length;
    if (isInvalid) {
      const leagueIdentifier = ['NBA', 'GLG'].includes(this.selectedLeague) ? 'NBA' : 'Synergy';
      const message = `An invalid filter was removed. Please ensure that all players have a valid ${leagueIdentifier} id.`;
      this.snackBar.open(message, 'Close', {
        duration: 5000,
      });
    }
    return isInvalid;
  }

  resetFilters(isHardReset = false, leagueUpdated = false, comingFromDef = false, switchingToDef = false, comingFromOff = false, switchingToOff = false) {
    this.isPristine = false;
    if (this.selectedFilterOption) {
      if (this.selectedFilterOption?.selectedAction?.selectedSubAction) {
        this.selectedFilterOption.selectedAction.selectedSubAction = null;
      }
      this.selectedFilterOption.selectedAction = null;
    }
    if (isHardReset) {
      // Hard reset is triggered by clicking "Generate" in the left video nav-menu
      this.filtersData.filters = _.cloneDeep(this.defaultFilters);
      this.filtersData.subject = VideoSubject.CHANCES;
      this.selectedFilterOption = videoEventFilterOptions[0];
      this.displayedFilters = _.cloneDeep(this.defaultDisplayedFilters);
      this.selectedLeague = 'NCAA';
      if (!leagueUpdated) {
        this.updateGameOptions();
      }
    } else if (switchingToDef || switchingToOff) {
      this.filtersData.filters = _.filter(this.filtersData.filters, (filt: any) => {
        return defOffPersistingFilters.includes(filt.name);
      });
      this.filtersData.subject = switchingToDef ? VideoSubject.DEFENSE : VideoSubject.OFFENSE;
      this.selectedFilterOption = _.find(videoEventFilterOptions, ['name', switchingToDef ? 'Defense' : 'Offense'])
      this.displayedFilters = _.cloneDeep(this.defaultDisplayedFilters);
      this.displayedFilters.leagueIDs = [1];
      this.selectedLeague = 'NBA';
    } else {
      this.filtersData.filters = _.filter(this.filtersData.filters, (filt: any) => {
        return persistingFilters.includes(filt.name) && (!comingFromDef || filt.name !== 'nba_defensive_player_ids') && (!comingFromOff || filt.name !== 'nba_offensive_player_ids');
      });
      this.displayedFilters = {
        actions: this.displayedFilters.actions || [],
        leagueIDs: this.displayedFilters.leagueIDs || [],
        onCourtEntities: this.displayedFilters.onCourtEntities || [],
        offCourtEntities: this.displayedFilters.offCourtEntities || [],
        offensiveLineupEntities: comingFromOff ? [] : this.displayedFilters.offensiveLineupEntities,
        defensiveLineupEntities: comingFromDef ? [] : this.displayedFilters.defensiveLineupEntities,
        primaryDefensivePlayer: this.displayedFilters.primaryDefensivePlayer || [],
        teamEntities: this.displayedFilters.teamEntities || [],
        defensiveTeamEntities: this.displayedFilters.defensiveTeamEntities || [],
        offensiveTeamEntities: this.displayedFilters.offensiveTeamEntities || [],
        abstractEntity: this.displayedFilters.abstractEntity || [],
        seasons: this.displayedFilters.seasons || [2023],
        minDistance: this.displayedFilters.minDistance || 0,
        maxDistance: this.displayedFilters.maxDistance || 30,
        minGameclock: this.displayedFilters.minGameclock || 0,
        maxGameclock: this.displayedFilters.maxGameclock || 720,
        minShotclock: this.displayedFilters.minShotclock || 0,
        maxShotclock: this.displayedFilters.maxShotclock || 24,
        ctgGames: this.displayedFilters.ctgGames || [],
        game_type: this.displayedFilters.game_type || [],
        periods: this.displayedFilters.periods || [],
        chances: this.displayedFilters.chances || [],
        possessions: this.displayedFilters.possessions || [],
        minDate: this.displayedFilters.minDate,
        maxDate: this.displayedFilters.maxDate,
        minQEFG: 0,
        maxQEFG: 1,
        minXEFG: 0,
        maxXEFG: 1,
      };
    }
    if (!leagueUpdated) {
      this.updateFiltersReference();
    }
  }

  addEntity(columnName, value, tableName = VideoTables.CHANCE_DETAILS) {
    this.isInvalid = false;
    this.updateFilter(columnName, value, false, tableName);
  }

  removeOnOffCourtEntity(entity: any, isOffCourt: boolean): void {
    const playerIDs = this.convertEntityIDs(entity);
    const fieldName = isOffCourt ? 'offCourtEntities' : 'onCourtEntities';
    this.displayedFilters[fieldName] = this.displayedFilters[fieldName].filter((onCourtEntity) => onCourtEntity.id != entity.id);
    const columnName = this.addColumnPrefix('on_court_player_ids');
    const onOffCourtEntitiesFilter = _.find(this.filtersData.filters, (filt) => {
      return filt.name === columnName && filt.not === isOffCourt && _.isEqual(_.sortBy(filt.args), _.sortBy(playerIDs));
    });
    this.filtersData.filters = _.without(this.filtersData.filters, onOffCourtEntitiesFilter);
    this.updateFiltersReference();
  }

  addOnOffCourtEntity(event, isOnCourt = true): void {
    const columnName = this.addColumnPrefix('on_court_player_ids');
    const entity = event.option.value;
    if (isOnCourt) {
      this.displayedFilters.onCourtEntities.push(entity);
      this.onCourtEntitiesInput.nativeElement.value = '';
      this.onCourtEntitiesControl.setValue(null);
    } else {
      this.displayedFilters.offCourtEntities.push(entity);
      this.offCourtEntitiesInput.nativeElement.value = '';
      this.offCourtEntitiesControl.setValue(null);
    }

    const playerIDs = this.convertEntityIDs(entity);

    this.filtersData.filters.push({
      table: this.VideoTables.CHANCE_DETAILS,
      name: columnName,
      not: !isOnCourt,
      args: playerIDs,
    });
    const isInvalid = this.updateFiltersReference();
    if (isInvalid && isOnCourt) {
      this.displayedFilters.onCourtEntities = _.filter(this.displayedFilters.onCourtEntities, (ent) => ent.id != entity.id);
    } else if (isInvalid) {
      this.displayedFilters.offCourtEntities = _.filter(this.displayedFilters.onCourtEntities, (ent) => ent.id != entity.id);
    }
  }

  removeLineupEntity(entity: any, isOffensive: boolean): void {
    const playerIDs = this.convertEntityIDs(entity);
    const fieldName = isOffensive ? 'offensiveLineupEntities' : 'defensiveLineupEntities';
    this.displayedFilters[fieldName] = this.displayedFilters[fieldName].filter((lineupEntity) => lineupEntity.id != entity.id);
    const columnName = this.addColumnPrefix(isOffensive ? 'offensive_player_ids' : 'defensive_player_ids');
    const lineupEntitiesFilter = _.find(this.filtersData.filters, (filt) => {
      return filt.name === columnName && _.isEqual(_.sortBy(filt.args), _.sortBy(playerIDs));
    });
    this.filtersData.filters = _.without(this.filtersData.filters, lineupEntitiesFilter);
    this.updateFiltersReference();
  }

  addLineupEntity(event, isOffensive: boolean): void {
    const columnName = this.addColumnPrefix(isOffensive ? 'offensive_player_ids' : 'defensive_player_ids');
    const entity = event.option.value;
    if (isOffensive) {
      this.displayedFilters.offensiveLineupEntities.push(entity);
      this.offensiveLineupEntitiesInput.nativeElement.value = '';
      this.offensiveLineupEntitiesControl.setValue(null);
    } else {
      this.displayedFilters.defensiveLineupEntities.push(entity);
      this.defensiveLineupEntitiesInput.nativeElement.value = '';
      this.defensiveLineupEntitiesControl.setValue(null);
    }

    const playerIDs = this.convertEntityIDs(entity);

    this.filtersData.filters.push({
      table: this.VideoTables.CHANCE_DETAILS,
      name: columnName,
      not: false,
      args: playerIDs,
    });
    const isInvalid = this.updateFiltersReference();
    if (isInvalid && isOffensive) {
      this.displayedFilters.offensiveLineupEntities = _.filter(this.displayedFilters.offensiveLineupEntities, (ent) => ent.id != entity.id);
    } else if (isInvalid) {
      this.displayedFilters.defensiveLineupEntities = _.filter(this.displayedFilters.defensiveLineupEntities, (ent) => ent.id != entity.id);
    }
  }

  addPrimaryDefensivePlayer(event, action): void {
    let columnName = action.columnName;
    const tableName = action.tableName || VideoTables.CHANCE_DETAILS;
    const entity = event.option.value;
    if (this.displayedFilters.primaryDefensivePlayer) {
      this.removePrimaryDefensivePlayer(columnName);
    }
    if ([VideoTables.CHANCE_DETAILS, VideoTables.ABSTRACT].includes(tableName)) {
      columnName = this.addColumnPrefix(columnName);
    }
    this.displayedFilters.primaryDefensivePlayer = entity;
    const nbaIDs = this.convertEntityIDs(entity);
    this.addEntity(columnName, nbaIDs, tableName);
    this.primaryDefensivePlayerInput.nativeElement.value = '';
    this.primaryDefensivePlayerControl.setValue(null);
    this.updateFiltersReference();
    // Filter is invalidated through addEntity so we have to use this.isInvalid instead of return value from updateFiltersReference
    if (this.isInvalid) {
      this.displayedFilters.primaryDefensivePlayer = null;
    }
  }

  removePrimaryDefensivePlayer(columnName): void {
    columnName = this.addColumnPrefix(columnName);
    this.removeEntity(columnName, 'primaryDefensivePlayer');
  }

  addTeamEntity(event, action): void {
    let columnName = action.columnName;
    const displayedFilterName = action.filterName;
    const entity = event.option.value;
    this.displayedFilters[displayedFilterName].push(entity);
    this[displayedFilterName + 'Input'].nativeElement.value = '';
    this[displayedFilterName + 'Control'].setValue(null);
    const teamIDs = this.convertEntityIDs(entity);
    columnName = this.addColumnPrefix(columnName);
    const teamFilter = _.find(this.filtersData.filters, ['name', columnName]);
    if (teamFilter == null) {
      this.filtersData.filters.push({
        table: this.VideoTables.CHANCE_DETAILS,
        name: columnName,
        not: false,
        args: teamIDs,
      });
    } else {
      teamFilter.args.push(...teamIDs);
    }

    this.updateFiltersReference();
  }

  removeTeamEntity(entity, action): void {
    let columnName = action.columnName;
    const displayedFilterName = action.filterName;
    const teamIDs = this.convertEntityIDs(entity);
    columnName = this.addColumnPrefix(columnName);
    const teamFilter = _.find(this.filtersData.filters, ['name', columnName]);
    if (teamFilter != null) {
      teamFilter.args = _.filter(teamFilter.args, (arg) => !teamIDs.includes(arg));
      this.displayedFilters[displayedFilterName] = _.filter(this.displayedFilters[displayedFilterName], (arg) => !teamIDs.includes(arg));
    }
    if (!teamFilter.args?.length) {
      this.filtersData.filters = _.without(this.filtersData.filters, teamFilter);
      this.displayedFilters[displayedFilterName] = [];
    }
    this.updateFiltersReference();
  }

  removeEntity(columnName, fieldName) {
    this.filtersData.filters = _.filter(this.filtersData.filters, (filt) => filt.name != columnName);
    this.displayedFilters[fieldName] = null;
    this.updateFiltersReference();
  }

  addPrimaryEntity(event, action): void {
    let columnName = action.columnName;
    const tableName = action.tableName || VideoTables.CHANCE_DETAILS;
    const entity = event.option.value;
    if (this.displayedFilters.primaryEntity) {
      this.removePrimaryEntity(columnName);
    }
    if ([VideoTables.CHANCE_DETAILS, VideoTables.ABSTRACT].includes(tableName)) {
      columnName = this.addColumnPrefix(columnName);
    }
    this.displayedFilters.primaryEntity = entity;
    const nbaIDs = this.convertEntityIDs(entity);
    this.addEntity(columnName, nbaIDs, tableName);
    this.primaryEntityInput.nativeElement.value = '';
    this.primaryEntityControl.setValue(null);
    this.updateFiltersReference();
    // Filter is invalidated through addEntity so we have to use this.isInvalid instead of return value from updateFiltersReference
    if (this.isInvalid) {
      this.displayedFilters.primaryEntity = null;
    }
  }

  removePrimaryEntity(columnName): void {
    columnName = this.addColumnPrefix(columnName);
    this.removeEntity(columnName, 'primaryEntity');
  }

  addAbstractEntity(event, action): void {
    const entity = event.option.value;

    this.displayedFilters.abstractEntity = entity;
    this.abstractEntityInput.nativeElement.value = '';
    this.abstractEntityControl.setValue(null);
    const isInvalid = this.updateFiltersReference();
    if (isInvalid) {
      this.displayedFilters.abstractEntity = null;
    }
  }

  removeAbstractEntity(): void {
    this.displayedFilters.abstractEntity = null;
    this.updateFiltersReference();
  }

  addSecondaryEntity(event, columnName): void {
    const entity = event.option.value;
    if (this.displayedFilters.secondaryEntity) {
      this.removeSecondaryEntity(columnName);
    }
    this.displayedFilters.secondaryEntity = entity;
    columnName = this.addColumnPrefix(columnName);
    const nbaIDs = this.convertEntityIDs(entity);
    this.addEntity(columnName, nbaIDs);
    this.secondaryEntityInput.nativeElement.value = '';
    this.secondaryEntityControl.setValue(null);
    this.updateFiltersReference();
    // Filter is invalidated through addEntity so we have to use this.isInvalid instead of return value from updateFiltersReference
    if (this.isInvalid) {
      this.displayedFilters.secondaryEntity = null;
    }
  }

  removeSecondaryEntity(columnName): void {
    columnName = this.addColumnPrefix(columnName);
    this.removeEntity(columnName, 'secondaryEntity');
  }

  addColumnPrefix(columnName: string) {
    const prefix = this.isNCAA ? 'synergy_' : 'nba_';
    if (!columnName.includes(prefix)) {
      columnName = prefix + columnName;
    }
    return columnName;
  }

  convertEntityIDs(entity) {
    entity = entity?.length ? entity[0] : entity;
    let nbaIDs;
    if (this.isNCAA) {
      nbaIDs = this.convertEntityToSynergyIDs([entity.id]);
    } else {
      nbaIDs = this.convertEntityToNBAIDs([entity.id]);
    }
    return nbaIDs;
  }

  updateSimpleCheckbox(column, action) {
    const columnName = action.fieldName;
    const tableName = action.tableName || VideoTables.CHANCE_DETAILS;
    const checkboxFilter = _.find(this.filtersData.filters, ['name', columnName]);
    let value = typeof column.value === 'object' ? column.value : [column.value];
    if (checkboxFilter == null) {
      this.filtersData.filters.push({
        'table': tableName,
        'name': columnName,
        'not': false,
        'args': value,
      });
      this.displayedFilters[columnName] = value;
    } else if (checkboxFilter.args.includes(column.value)) {
      value = _.without(checkboxFilter.args, column.value);
      checkboxFilter.args = value;
      if (value?.length == 0) {
        this.filtersData.filters = _.without(this.filtersData.filters, checkboxFilter);
      }
    } else if (typeof column.value === 'object' && _.difference(column.value, checkboxFilter.args).length === 0) {
      value = _.without(checkboxFilter.args, ...column.value);
      checkboxFilter.args = value;
      if (value?.length == 0) {
        this.filtersData.filters = _.without(this.filtersData.filters, checkboxFilter);
      }
    } else {
      value = _.uniq(checkboxFilter.args.concat(column.value));
      checkboxFilter.args = value;
    }
    this.displayedFilters[columnName] = value;
    this.updateFiltersReference();
  }

  isSimpleCheckboxIncluded(column, action) {
    const columnName = column.fieldName || action.fieldName;
    return this.displayedFilters[columnName]?.includes(column.value) ||
    (typeof column.value === 'object' && _.intersection(column.value, this.displayedFilters[columnName]).length);
  }

  updateFiltersReference() {
    this.isPristine = false;
    const isInvalid = this.removeInvalidFilters();
    this.isInvalid = isInvalid || this.isInvalid;
    // Update object ref to manually trigger video-filter-panel change detection
    this.filtersData = {...this.filtersData};
    this.displayedFilters = {...this.displayedFilters};
    this.updateGameOptions();
    this.cdr.markForCheck();
    return isInvalid;
  }

  addAggregatedCheckboxValue(checkboxFilter, notFlag, attributeName, columnName, groupName) {
    if (this.VideoDefenseTags.includes(columnName) || this.VideoOffenseTags.includes(columnName)) {
      this.displayedFilters[columnName] = 1;
    }
    this.displayedFilters[groupName + attributeName + columnName] = 1;
    if (checkboxFilter?.args) {
      checkboxFilter.args.push(attributeName);
    } else {
      this.filtersData.filters.push({
        'table': this.VideoDefenseTags.includes(columnName) || this.VideoOffenseTags.includes(columnName) ? VideoTables.CHANCE_DETAILS : VideoTables.ACTION,
        'name': columnName,
        'group': groupName,
        'not': notFlag,
        'args': [attributeName],
      });
    }
  }

  updateGames(game) {
    const includedGame = _.find(this.displayedFilters.ctgGames, game);

    if (includedGame) {
      this.displayedFilters.ctgGames = _.without(this.displayedFilters.ctgGames, includedGame);
    } else {
      this.displayedFilters.ctgGames.push(game);
    }

    this.updateFiltersReference();
  }

  checkDefOffItems(columnName): Boolean {
    const isDefOffTag = this.VideoDefenseTags.includes(columnName) || this.VideoOffenseTags.includes(columnName);
    const isBoolean = this.BooleanDefenseTags.includes(columnName);
    const isCrossmatched = _.find(this.filtersData.filters, ['name', 'nba_player_ids_crossmatched']);
    if (isCrossmatched && this.crossmatchedMap.hasOwnProperty(columnName) && !isCrossmatched.not) columnName = this.crossmatchedMap[columnName];
    if (!isDefOffTag) return false;
    if (isBoolean && this.displayedFilters[columnName] == 0) {
      return false;
    }
    return this.displayedFilters[columnName] != null;
  }

  removeAggregatedCheckboxValue(checkboxFilter, attributeName, columnName, groupName, shouldNullValue = false) {
    if (shouldNullValue) {
      if(this.BooleanDefenseTags.includes(columnName)) {
        this.displayedFilters[columnName] = null;
      }
      this.displayedFilters[groupName + attributeName + columnName] = null;
    } else {
      if(this.BooleanDefenseTags.includes(columnName)) {
        this.displayedFilters[columnName] = 0;
      }
      this.displayedFilters[groupName + attributeName + columnName] = 0;
    }
    if (this.VideoDefenseTags.includes(columnName) || this.VideoOffenseTags.includes(columnName)) {
      if(!this.BooleanDefenseTags.includes(columnName)) {
        this.displayedFilters[columnName] = null;
      }
      const filter = _.find(this.filtersData.filters, ['name', columnName]);
      this.filtersData.filters = _.without(this.filtersData.filters, filter);
    } else {
      checkboxFilter.args = _.without(checkboxFilter.args, attributeName);
      if (!checkboxFilter.args.length) {
        this.filtersData.filters = _.without(this.filtersData.filters, checkboxFilter);
      }
    }
  }

  updateAggregatedCheckboxValue($event, column) {
    const columnName = column.columnName;
    const attributeName = column.value;
    const groupName = column.group;
    const trueCheckboxFilter = _.find(this.filtersData.filters, (filt) => filt.name === columnName && filt.group === groupName && !filt.not);
    const falseCheckboxFilter = _.find(this.filtersData.filters, (filt) => filt.name === columnName && filt.group === groupName && filt.not);
    const selectedFilter = _.find(this.filtersData.filters, (filt) => filt.name === columnName && filt.group === groupName);
    const isCrossmatched = _.find(this.filtersData.filters, ['name', 'nba_player_ids_crossmatched']);

    if ((this.VideoDefenseTags.includes(columnName) || this.VideoOffenseTags.includes(columnName)) && !this.BooleanDefenseTags.includes(columnName)) {
        if (selectedFilter) {
          if (isCrossmatched && this.crossmatchedMap.hasOwnProperty(selectedFilter.name)) {
            const crossmatchedKey = this.crossmatchedMap[selectedFilter.name];
            this.removeAggregatedCheckboxValue(selectedFilter, attributeName, crossmatchedKey, groupName, true);
          } 
          this.removeAggregatedCheckboxValue(selectedFilter, attributeName, columnName, groupName, true);
        } else {
          this.addAggregatedCheckboxValue(selectedFilter, false, attributeName, columnName, groupName);
        }
    } else {
      if (falseCheckboxFilter?.args?.includes(attributeName) || (this.BooleanDefenseTags.includes(columnName) && falseCheckboxFilter?.name == columnName)) {
          this.removeAggregatedCheckboxValue(falseCheckboxFilter, attributeName, columnName, groupName, true);
      } else if (trueCheckboxFilter?.args?.includes(attributeName) || (this.BooleanDefenseTags.includes(columnName) && trueCheckboxFilter?.name == columnName)) {
          // We don't want the check to disappear
          $event.preventDefault();
          $event.stopImmediatePropagation();
          this.addAggregatedCheckboxValue(falseCheckboxFilter, true, attributeName, columnName, groupName);
          this.removeAggregatedCheckboxValue(trueCheckboxFilter, attributeName, columnName, groupName);
      } else {
          this.addAggregatedCheckboxValue(trueCheckboxFilter, false, attributeName, columnName, groupName);
      }
    }
    this.updateFiltersReference();
}

  updateCheckboxValue($event, column) {
    const columnName = column.columnName;
    const checkboxFilter = _.find(this.filtersData.filters, ['name', columnName]);
    if (checkboxFilter == null) {
      column.value = 1;
      this.filtersData.filters.push({
        'table': VideoTables.CHANCE_DETAILS,
        'name': columnName,
        'not': false,
        'args': [column.isBoolean ? true : 1],
      });
      this.displayedFilters[columnName] = 1;
    } else if (_.isEqual(checkboxFilter.args, [column.isBoolean ? true : 1])) {
      // We don't want the check to disappear
      $event.preventDefault();
      $event.stopImmediatePropagation();
      checkboxFilter.args = [column.isBoolean ? false : 0];
      this.displayedFilters[columnName] = 0;
      column.value = 0;
    } else {
      this.filtersData.filters = _.without(this.filtersData.filters, checkboxFilter);
      column.value = null;
      this.displayedFilters[columnName] = null;
    }
    this.updateFiltersReference();
  }

  checkDefOffValues() {
    const isDefense = this.selectedFilterOption.name == 'Defense';
    const isOffense = this.selectedFilterOption.name == 'Offense';
    let playerFilter;
    if (isDefense) {
      playerFilter = _.find(this.filtersData.filters, ['name', 'nba_defensive_player_ids']);
    } else if (isOffense) {
      playerFilter = _.find(this.filtersData.filters, ['name', 'nba_offensive_player_ids']);
    }
    if (!playerFilter) return;
    const isCrossmatched = _.find(this.filtersData.filters, ['name', 'nba_player_ids_crossmatched']);
    const isCrossmatchedFalse = isCrossmatched ? isCrossmatched.not : false;
    this.filtersData.filters.forEach((filter) => {
      const isDefOffTag = this.VideoDefenseTags.includes(filter.name) || this.VideoOffenseTags.includes(filter.name);
      const isSpecialTag = this.SpecialDefenseTags.includes(filter.name);
      const crossMatch = this.crossmatchedMap[filter.name];
      const reverseCrossMatch = this.reverseCrossmatchedMap[filter.name];
      if (isDefOffTag || isSpecialTag) {
        filter.args = playerFilter.args;
      }
      if (isCrossmatched && !isCrossmatchedFalse && crossMatch) {
        filter.name = crossMatch;
      } else if ((!isCrossmatched || isCrossmatchedFalse) && reverseCrossMatch) {
        filter.name = reverseCrossMatch;
      }
      if (isSpecialTag) {
        const position = this.positionMap[filter.name];
        filter.position = position
      }
    });
  }

  onPositionChange(entity, isOffensive) {
    const playerIDs = this.convertEntityIDs(entity);
    const position = this.lineupMap[entity.selectedPosition];
    const fieldName = isOffensive ? 'nba_offensive_player_ids' : 'nba_defensive_player_ids';
    const curPlayerFilter = _.find(this.filtersData.filters, (filt) => {
      return _.isEqual(_.sortBy(filt.args), _.sortBy(playerIDs)) && filt.name === fieldName;
    });
    if (curPlayerFilter) {
      curPlayerFilter.args = playerIDs;
      curPlayerFilter.position = position ?? null;
    }
    this.updateFiltersReference();
  }

  getSelectedPosition(entity, isOffensive) {
    const playerIDs = this.convertEntityIDs(entity);
    const fieldName = isOffensive ? 'nba_offensive_player_ids' : 'nba_defensive_player_ids';
    const curPlayerFilter = _.find(this.filtersData.filters, (filt) => {
      return _.isEqual(_.sortBy(filt.args), _.sortBy(playerIDs)) && filt.name === fieldName;
    });
    if (curPlayerFilter && curPlayerFilter.position) {
      const selectedPosition = this.reverseLineupMap[curPlayerFilter.position];
      entity.selectedPosition = selectedPosition;
      return selectedPosition;
    }
    entity.selectedPosition = 'ANY';
    return 'ANY';
  }

  getCorrectPCItem() {
    return (this.selectedFilterOption.name == 'Defense' || this.selectedFilterOption.name == 'Offense') ? this.DefOffPossessionsAndChancesItem : this.possessionsAndChancesItem;
  }

  updateLeague(leagueName: string, leagueID: number) {
    this.resetFilters(true, true);
    this.selectedLeague = leagueName;
    this.displayedFilters.leagueIDs = leagueID ? [leagueID] : [];
    this.updateFilter('league_id', leagueID ? [leagueID] : [], false, VideoTables.CHANCE_DETAILS, false);
    this.updateFiltersReference();
  }

  updateFilter(columnName, value, appendValues = false, tableName = VideoTables.CHANCE_DETAILS, shouldMarkDirty = true, isEntity = false) {
    const filter = _.find(this.filtersData.filters, ['name', columnName]);
    if (filter == null) {
      this.filtersData.filters.push({
        'table': tableName,
        'name': columnName,
        'group': tableName === VideoTables.ABSTRACT ? this.filtersData.subject : null,
        'not': false,
        'args': appendValues ? [value] : value,
      });
    } else if (appendValues) {
      if (filter.args.includes(value)) {
        filter.args = _.without(filter.args, value);
      } else {
        filter.args.push(value);
      }
    } else if (_.isEqual(value, [])) {
      this.filtersData.filters = _.without(this.filtersData.filters, filter);
    } else {
      filter.args = value;
    }
    if (shouldMarkDirty) {
      this.updateFiltersReference();
    }
  }

  updateSeason(seasonChange: MatLegacySelectChange) {
    this.updateFilter('season', seasonChange.value);
    this.updateGameOptions();
  }

  updatePeriod(seasonChange: MatLegacySelectChange) {
    this.updateFilter('period', seasonChange.value);
  }

  updateGameClock() {
    const minValue = this.displayedFilters.minGameclock ? this.displayedFilters.minGameclock : this.minGameClock;
    const maxValue = this.displayedFilters.maxGameclock ? this.displayedFilters.maxGameclock : this.maxGameClock;
    this.updateMinMaxFilter('starting_seconds_left_in_period', minValue, maxValue);
  }

  updateShotClock() {
    const minValue = this.displayedFilters.minShotclock ? this.displayedFilters.minShotclock : this.minShotClock;
    const maxValue = this.displayedFilters.maxShotclock ? this.displayedFilters.maxShotclock : this.maxShotClock;
    this.updateMinMaxFilter('shot_clock', minValue, maxValue);
  }

  updateDribbles() {
    const minValue = this.displayedFilters.touchMinDribbles ? this.displayedFilters.touchMinDribbles : this.minDribbles;
    const maxValue = this.displayedFilters.touchMaxDribbles ? this.displayedFilters.touchMaxDribbles : this.maxDribbles;
    this.updateMinMaxFilter('numdribbles', minValue, maxValue, VideoTables.TOUCHES);
  }

  updateDuration() {
    const minValue = this.displayedFilters.touchMinDuration ? this.displayedFilters.touchMinDuration : this.minDuration;
    const maxValue = this.displayedFilters.touchMaxDuration ? this.displayedFilters.touchMaxDuration : this.maxDuration;
    this.updateMinMaxFilter('touchtime', minValue, maxValue, VideoTables.TOUCHES);
  }

  updateDistance() {
    const minValue = this.displayedFilters.minDistance ? this.displayedFilters.minDistance : this.minDistance;
    const maxValue = this.displayedFilters.maxDistance ? this.displayedFilters.maxDistance : this.maxDistance;
    const fieldName = this.isNCAA ? 'synergy_shot_distance' : 'eagle_shot_distance';
    this.updateMinMaxFilter(fieldName, minValue, maxValue);
  }

  updateQEFG() {
    const minValue = this.displayedFilters.minQEFG ? this.displayedFilters.minQEFG : 0;
    const maxValue = this.displayedFilters.maxQEFG ? this.displayedFilters.maxQEFG : 1;
    this.updateMinMaxFilter('shot_qefg', minValue, maxValue);
  }

  updateXEFG() {
    const minValue = this.displayedFilters.minXEFG ? this.displayedFilters.minXEFG : 0;
    const maxValue = this.displayedFilters.maxXEFG ? this.displayedFilters.maxXEFG : 1;
    this.updateMinMaxFilter('shot_xefg', minValue, maxValue);
  }

  compareGameIDs(a, b) {
    return a && b && ((a.synergyGameID && b.synergyGameID && a.synergyGameID === b.synergyGameID) || (a.nbaGameID && b.nbaGameID && a.nbaGameID === b.nbaGameID));
  }

  gamePassesSearchFilter(gameOption) {
    const gameString = `${moment(gameOption.gameStartTime).format('M/D/YY')}: ${gameOption.awayTeamName} @ ${gameOption.homeTeamName}`.toLowerCase();
    const searchRegex = new RegExp(`(?=.*${this.gameSearchFilterValue.trim().toLowerCase().split(' ').join(')(?=.*')})`);
    return searchRegex.test(gameString);
  }

  updateTextArea(event: any, columnName: string) {
    // Switch from space delimited to comma delimited
    let value = event.target.value.replace(/\s/g, ',');
    value = value.replace(',,', ',');
    if (value == '') {
      const filterToRemove = _.find(this.filtersData.filters, ['name', columnName]);
      this.filtersData.filters = _.without(this.filtersData.filters, filterToRemove);
    } else {
      if (value[value.length - 1] === ',') {
        value = value.slice(0, -1);
      }
      value = value.split(',');
      this.displayedFilters[columnName] = value;
      this.updateFilter(columnName, value);
    }
    this.updateFiltersReference();
  }

  openRecentGeneratedPlaylist(id: number) {
    this.resetFilters();
    this.generatedPlaylistParameters.emit({id: id});
  }

  getGeneratedPlaylistPrimaryEntity(generatedPlaylist) {
    if (generatedPlaylist.filters?.primaryEntity?.length) {
      return generatedPlaylist.filters.primaryEntity[0];
    } else if (generatedPlaylist.filters?.primaryEntity) {
      return generatedPlaylist.filters.primaryEntity;
    } else if (generatedPlaylist.filters?.abstractEntity?.length) {
      return generatedPlaylist.filters.abstractEntity[0];
    } else if (generatedPlaylist.filters?.abstractEntity) {
      return generatedPlaylist.filters.abstractEntity;
    } else if (generatedPlaylist.filtersData.subject == VideoSubject.DEFENSE) {
      return generatedPlaylist.filters.defensiveLineupEntities[0];
    } else if (generatedPlaylist.filtersData.subject == VideoSubject.OFFENSE) {
      return generatedPlaylist.filters.offensiveLineupEntities[0];
    } else {
      return null;
    }
  }

  determineGPHeader(generatedPlaylist, includeEntity = true) {
    const header = `${videoSubjectToLabel(generatedPlaylist.filtersData.subject)}`;
    const entity = this.getGeneratedPlaylistPrimaryEntity(generatedPlaylist);
    if (entity && includeEntity) {
      return `${entity.name} ${header}`;
    } else {
      return header;
    }
  }

  getActionFilter(action) {
    const actionFilter = _.find(this.filtersData.filters, (filt) => {
      return filt.name === 'name' && filt.group === action;
    });
    return actionFilter;
  }

  removeSelectedActionItem(action) {
    if (!this.displayedFilters.actions?.length || action == this.selectedFilterOption?.selectedAction?.action) {
      this.selectedFilterOption.selectedAction = null;
    }
  }

  removeDisplayedAttributes(action) {
    Object.keys(this.displayedFilters).forEach((key) => {
      if (key.includes(action) && key.includes('descriptors')) {
        // We set this as 2 because no styling is applied in that circumstance and null will be overwritten
        this.displayedFilters[key] = 2;
      }
    });
  }

  toggleActionFilter(action) {
    this.isPristine = false;
    const actionFilter = this.getActionFilter(action);

    if (actionFilter) {
      this.filtersData.filters = _.filter(this.filtersData.filters, (filt) => {
        return filt.group !== action;
      });
      this.displayedFilters.actions = _.without(this.displayedFilters.actions, action);
      this.removeSelectedActionItem(action);
      this.removeDisplayedAttributes(action);
    } else {
      this.filtersData.filters.push({
        'table': VideoTables.ACTION,
        'name': 'name',
        'group': action,
        'not': false,
        'args': videoSubjectToColumnName(action),
      });
      this.displayedFilters.actions.push(action);
    }

    this.updateFiltersReference();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onMouseLeave() {
    this.videoService.hideFilterPanelSubject.next(true);
  }

  ngOnDestroy() {
  }
}
