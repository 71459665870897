import {NavOption} from '@models/nav-option';

export class PersonPostFeedNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Post Feed';
  shouldHide= false;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id];
    this.link = ['', 'bild', 'entities', 'people', person.id];
  }
}

export class PersonHighlightsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Highlights';
  shouldHide= false;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'highlights'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'highlights'];
  }
}

export class PersonEvaluationNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Evaluation';
  shouldHide;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'evaluation'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'evaluation', 'locs'];
    this.shouldHide = person.hideLOCs;
  }
}

export class PersonComparisonNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Comparables';
  shouldHide;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'comparison'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'comparison'];
    this.shouldHide = person.hideMetrics;
  }
}

export class PersonDevelopmentNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Player Development';
  shouldHide;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'development'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'development'];
    this.shouldHide = person.hideDevelopment;
  }
}

export class PersonAffiliationsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Affiliations';
  shouldHide = false;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'affiliations'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'affiliations'];
  }
}

export class PersonFocusGroupsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Focus Groups';
  shouldHide = true;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'focus-groups'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'focus-groups'];
  }
}

export class PersonMetricsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Metrics';
  shouldHide;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'metrics'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'metrics', 'measures'];
    this.shouldHide = person.hideMetrics;
  }
}

export class PersonPhysicalNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Physical';
  shouldHide;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'physical'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'physical', 'overview'];
    this.shouldHide = person.hidePhysical;
  }
}

export class PersonPsychNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Psych';
  shouldHide = false;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'psych'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'psych'];
  }
}

export class PersonCommunityBoardNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Community Board';
  shouldHide;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'community-board'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'community-board'];
    this.shouldHide = true;
  }
}

export class PersonPersonalNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Personal';
  shouldHide = false;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'personal'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'personal'];
  }
}

export class PersonOwnershipProfileNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Ownership Profile';
  shouldHide = false;
  constructor(person) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'people', person.id, 'ownership-profile'];
    this.link = ['', 'bild', 'entities', 'people', person.id, 'ownership-profile'];
  }
}
