import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {AutocompleteService} from '@services/autocomplete.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {EntitiesService} from '@services/entities.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Title} from '@angular/platform-browser';
import {VideoService} from '@services/video.service';
import {VideoMetrics} from '@models/constants/video/video-metric-types';
import * as _ from 'lodash';
import {User} from '@models/users';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as moment from 'moment-timezone';
import {ConnectionPositionPair} from '@angular/cdk/overlay';
import * as actions from "@store/video-store/actions";
import {Store} from "@ngrx/store";
import {RootStoreState} from "@store/index";
import {VideoHelper} from "@helpers/video.helper";
import {DOMHelper} from "@helpers/dom.helper";
import {TableFilterComponent} from "../table-filter/table-filter.component";

@UntilDestroy()
@Component({
  selector: 'video-clip-table',
  templateUrl: './video-clip-table.component.html',
  styleUrls: ['./video-clip-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class VideoClipTableComponent implements OnInit, OnDestroy {
  @ViewChild('clipList') clipList;
  @ViewChild('labelContainer') labelContainer;
  @ViewChild('viewport') viewPort: CdkVirtualScrollViewport;
  @ViewChildren(TableFilterComponent) tableFilters: ElementRef[];

  readonly nbaIDLookup = {
    'shooter-name': 'nbaShooterID',
    'assister-name': 'nbaAssisterID',
    'potential-assister-name': 'nbaPotentialAssisterID',
    'blocker-name': 'nbaBlockerID',
    'rebounder-name': 'nbaRebounderID'
  };
  readonly synergyIDLookup = {
    'shooter-name': 'synergyShooterID',
    'assister-name': 'synergyAssisterID',
    'blocker-name': 'synergyBlockerID',
    'rebounder-name': 'synergyRebounderID',
    'cutter-name': 'synergyCutterID',
    'ballhandler-name': 'synergyBallHandlerID',
    'screener-name': 'synergyScreenerID',
    'passer-name': 'synergyPasserID'
  };

  @Input() set clipsOnSide(val: boolean) {
    if (val != null) {
      this._clipsOnSide = val;
      this.recalculateViewportSize();
      if (val) {
        this.scrollSpeed = 1;
      } else {
        this.scrollSpeed = 0.25;
      }
      this.cdr.markForCheck();
    }
  };

  get clipsOnSide() {
    return this._clipsOnSide;
  }

  @Input() set page(val: number) {
    if (val) {
      this._page = val;
      this.cdr.markForCheck();
    }
  };

  get page() {
    return this._page;
  }

  @Input() set displayedClips(val) {
    if (val && (!this.displayedClips || !this.videoHelper.isSameClips(val, this.displayedClips))) {
      this._displayedClips = val;
      this.tableData = val;
      this.cdr.markForCheck();
    }
  };

  get displayedClips() {
    return this._displayedClips;
  }

  @Input() set unfilteredClips(val) {
    if (val) {
      this._unfilteredClips = val;
      this.initializeColumnData();
      this.updateTableFilters();
      this.updateScrollIndex();
      this.cdr.markForCheck();
    }
  };

  get unfilteredClips() {
    return this._unfilteredClips;
  }

  @Input() set tableClassName(val) {
    if (val) {
      this._tableClassName = val;
      this.cdr.markForCheck();
    }
  };

  get tableClassName() {
    return this._tableClassName;
  }

  @Input() set columns(val) {
    if (val) {
      this._columns = val;
      this.initializeColumnData();
      this.cdr.markForCheck();
    }
  };

  get columns() {
    return this._columns;
  }

  @Input() set currentVideoIndex(val) {
    if (val != null) {
      this._currentVideoIndex = val;
      this.updateScrollIndex();
      this.cdr.markForCheck();
    }
  };

  get currentVideoIndex() {
    return this._currentVideoIndex;
  }

  @Input() activeFilters: any;
  @Input() currentClip: any;
  @Input() currentEpv: number = -1.0;
  @Input() filters: any;
  @Input() fullQuarters: boolean;
  @Input() isDarkMode: boolean;
  @Input() isDragEnabled: boolean;
  @Input() isSaving: boolean;
  @Input() isSavingMany: boolean;
  @Input() isSelectedClips: boolean;
  @Input() isShuffled: boolean;
  @Input() isSmallerDialog: boolean;
  @Input() notesVisible: boolean;
  @Input() pageSize: number = 100;
  @Input() savedVideosLookup: any;
  @Input() selectedLeague: string;
  @Input() selectedClips: any[];
  @Input() showFooterBar: boolean;
  @Input() showPaginationBar: boolean;
  @Input() showExpansionButton: boolean;
  @Input() sortBy: string;
  @Input() sortDirection: string;
  @Input() isTableExpanded: boolean;
  @Input() user: User;

  @Output() onChangeToClip: EventEmitter<number> = new EventEmitter();
  @Output() onClipDrop: EventEmitter<number> = new EventEmitter();
  @Output() onClipNotesUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onSaveClip: EventEmitter<any> = new EventEmitter();
  @Output() onSortUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onToggleAllSelected: EventEmitter<any> = new EventEmitter();
  @Output() onToggleTableExpanded: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateSelectedClips: EventEmitter<any> = new EventEmitter();

  _clipsOnSide: boolean = false;
  _columns: any[];
  _currentVideoIndex: number = 0;
  _displayedClips: any[];
  _page: number;
  _tableClassName: string;
  _unfilteredClips = [];
  filteredTableData = [];
  tableData = [];

  currentTarget: Element;
  originalZIndexValue: number = 0;
  overlayClip: any;
  raisedZIndexValue: number = 102;
  scrollAccelerationFactor: number = 0;
  scrollDirection;
  scrollIndexRaw: number = 0;
  scrollSpeed: number = 0.25;
  scrollUpdated$: Subject<any> = new Subject<any>();
  onNotesChanged: any = _.debounce(this.saveNotes, 500);
  tableFilterVisible: boolean = false;

  readonly debounceTime: number = 7;

  public positions = [
    new ConnectionPositionPair({
      originX: 'start',
      originY: 'center',
    },
    {
      overlayX: 'end',
      overlayY: 'center',
    },
    0,
    0),
  ];

  get inverseTranslation(): string {
    return `-${this.viewPort?.getOffsetToRenderedContentStart()}px`;
  }

  constructor(
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected domHelper: DOMHelper,
    protected entitiesService: EntitiesService,
    protected matDialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected store$: Store<RootStoreState.State>,
    protected title: Title,
    protected videoHelper: VideoHelper,
    protected videoService: VideoService,
  ) {
  }

  @HostListener('wheel', ['$event'])
  onWheelScroll(wheelEvent) {
    if (this.isSelectedClips || Math.abs(wheelEvent.wheelDeltaY) < Math.abs(wheelEvent.wheelDeltaX)) {
      return;
    }
    wheelEvent.stopImmediatePropagation();
    wheelEvent.stopPropagation();
    wheelEvent.preventDefault();
    if (wheelEvent.wheelDeltaY > 0) {
      this.updateScroll(true);
    } else {
      this.updateScroll(false);
    }
    if (this.overlayClip) {
      this.overlayClip.isOverlayVisible = false;
    }
    this.cdr.markForCheck();
  }

  ngOnInit() {
  }

  updateScroll(isUp: boolean) {
    if (this.scrollUpdated$.observers.length === 0) {
      this.scrollUpdated$
          .pipe(debounceTime(500), untilDestroyed(this))
          .subscribe((isUp) => {
            this.scrollDirection = null;
            this.scrollAccelerationFactor = 0;
          });
      this.scrollUpdated$
          .pipe(debounceTime(this.debounceTime), untilDestroyed(this))
          .subscribe((isUp) => {
            if (this.scrollDirection == isUp && !this.clipsOnSide) {
              this.scrollAccelerationFactor += 0.05;
            } else {
              this.scrollAccelerationFactor = 0;
            }
            this.scrollDirection = isUp;
            if (isUp) {
              this.scrollUp();
            } else {
              this.scrollDown();
            }
          });
    }
    this.scrollUpdated$.next(isUp);
  }

  updateScrollIndex() {
    setTimeout(() => {
      const newScrollIndex = Math.max(0, this.currentVideoIndex - 2);
      this.scrollIndexRaw = newScrollIndex;
      if (this.viewPort) {
        this.viewPort.scrollToIndex(newScrollIndex);
      }
      this.cdr.markForCheck();
    }, 50);
  }

  scrollDown() {
    const maxIndex = this.tableData?.length - 1;
    const scrollSpeed = (1 + this.scrollAccelerationFactor) * this.scrollSpeed;
    if (this.scrollIndexRaw < maxIndex) {
      this.scrollIndexRaw += scrollSpeed;
    }
    const newIndex = Math.min(maxIndex, Math.floor(this.scrollIndexRaw));
    this.viewPort.scrollToIndex(newIndex);
    this.cdr.markForCheck();
  }

  scrollUp() {
    const scrollSpeed = (1 + this.scrollAccelerationFactor) * this.scrollSpeed;
    // ScrollToIndex sets an index as the first index in the table, so scrolling up from bottom requires us to
    // subtract the number of elements visible in table
    if (this.scrollIndexRaw >= this.tableData?.length - 1) {
      const viewportSize = this.viewPort.getViewportSize();
      this.scrollIndexRaw -= viewportSize / 32;
    }
    if (this.scrollIndexRaw > 0) {
      this.scrollIndexRaw -= scrollSpeed;
    }
    const newIndex = Math.max(0, Math.ceil(this.scrollIndexRaw));
    this.viewPort.scrollToIndex(newIndex);
    this.cdr.markForCheck();
  }

  ncaaOnCourtCoverageMessage(clipParams) {
    if (clipParams.league == 'NCAA' && this.filters.metricType == VideoMetrics.CHANCES) {
      if (clipParams.draftExpressPBPCoverage === false) {
        return 'Game has no DraftExpress PBP';
      }
      if (clipParams.hasDraftExpressPBPMap === false) {
        return 'Game has no oncourt data';
      }
      if (clipParams.coveragePct != 100) {
        return 'Oncourt data is complete for ' + clipParams.coveragePct.toFixed(1) + '% of the game';
      }
    }
    return null;
  }

  changeToClip(index) {
    this.onChangeToClip.emit(index);
  }

  displayDate(date) {
    if (date instanceof Date) {
      return this.currentClip.game.date = date.getMonth() + 1 + '/' + date.getDate() + '/' + (date.getFullYear() % 100);
    }
    return date;
  }

  toggleAllSelected($event) {
    this.onToggleAllSelected.emit($event);
  }

  updateSelectedClips(clip) {
    const clipID = clip.id || clip.eagleChanceID || clip.synergyEventID || clip.eagleChanceID || clip.nbaChanceID;
    const isSelected = clip.isSelected;
    this.onUpdateSelectedClips.emit({id: clipID, isSelected: isSelected});
  }

  roundNumber(num) {
    return Math.round(num);
  }

  sortTable(sortColumn?: string) {
    if (this.sortDirection && this.sortBy === sortColumn) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : '';
    } else if (sortColumn) {
      this.sortDirection = 'desc';
    }

    this.sortBy = sortColumn;
    this.onSortUpdate.emit({'column': sortColumn, 'direction': this.sortDirection});
    this.cdr.markForCheck();
  }

  determineSortClass(column) {
    return this.sortBy === column ? this.sortDirection : '';
  }

  recalculateViewportSize(timeout = 30) {
    setTimeout(() => {
      this.viewPort?.checkViewportSize();
      this.cdr.markForCheck();
    }, timeout);
  }

  saveNotes(clip) {
    this.onClipNotesUpdate.emit(clip);
  }

  updateTableFilters() {
    if (this.tableFilters) {
      this.tableFilters.forEach((tableFilter: any) => {
        tableFilter.initializeForm();
        tableFilter.setupForm(true);
      });
      this.cdr.markForCheck();
    }
  }

  initializeColumnData() {
    if (this.columns && this.unfilteredClips) {
      this.columns.forEach((column) => {
        if (column.isFilterable) {
          const columnValueMap = {};
          const key = this.videoHelper.getDataAccessor(column.matColumnDef);
          const sortedClips = _.sortBy(this.unfilteredClips, (c) => _.get(c, this.videoHelper.getRowSpecificAccessor(c, key)));
          let columnValues = [];
          let checkboxImages = [];
          if (['offTeam', 'defTeam'].includes(key)) {
            const baseUrl = 'https://docs-public.imgix.net/';
            columnValues = _.uniq(_.map(sortedClips, (clip) => {
              return _.get(clip, this.videoHelper.getRowSpecificAccessor(clip, key));
            }));
            checkboxImages = _.uniq(_.map(sortedClips, (clip) => {
              return (clip.homeHasPossession && key === 'offTeam') || (!clip.homeHasPossession && key !== 'offTeam') ? baseUrl + clip.homeTeamImage : baseUrl + clip.awayTeamImage;
            }));
          } else if (key.includes('-name')) {
            const nbaID = this.nbaIDLookup[key];
            const synergyID = this.synergyIDLookup[key];
            sortedClips.forEach((clip) => {
              const value = _.get(clip, this.videoHelper.getRowSpecificAccessor(clip, key));
              const imageValue = clip[nbaID] ? clip.nbaEntityMap?.[clip[nbaID]]?.imageUrl
                              : clip[synergyID] ? clip.synergyEntityMap?.[clip[synergyID]]?.imageUrl
                              : null;
              if (value && !columnValueMap[value]) {
                columnValues.push(value);
                checkboxImages.push(imageValue);
                columnValueMap[value] = true;
              }
            });
          } else if (key === 'period') {
            columnValues = _.uniq(_.map(sortedClips, (clip) => String(clip.period)));
          } else if (key === 'isMake') {
            columnValues = _.uniq(_.map(sortedClips, (clip) => (clip.isMake ? 'Make' : 'Miss'))).sort();
          } else if (key === 'actionName') {
            const actionNames = _.uniqBy(sortedClips, clip => JSON.stringify(clip.actionName));
            columnValues = actionNames.map(clip => this.videoHelper.actionDescriptionHelper(clip.actionName)).filter(Boolean);
          } else if (key === 'styleOfPlayTag') {
            columnValues = ['Really Bad', 'Bad', 'Average', 'Good', 'Really Good'];
          } else {
            columnValues = _.uniq(_.map(sortedClips, key));
          }

          // Remove nullish values
          column.checkboxImages = _.filter(checkboxImages);
          column.values = _.filter(columnValues);
        }
      });
    }
  }

  getStyleOfPlayTag(clip: any): string {
    if (!clip?.tags?.length) return '';
    const tag = clip.tags.find(
      tag => tag.definition.name === 'Style of Play' &&
             tag.metadata?.length === 1 &&
             tag.author?.userID === this.user.id &&
             tag.metadata?.[0]?.option?.name
    );
    return tag?.metadata?.[0]?.option?.name || '';
  }

  filterData(values, key) {
    this.onFilterUpdate.emit({values: values, key: key});
  }


  handleBlur(isVisible, event) {
    event.stopPropagation();
    event.preventDefault();
    if (isVisible) {
      this.tableFilterVisible = isVisible;
    } else if (!event.currentTarget.contains(event.relatedTarget)) {
      this.tableFilterVisible = isVisible;
    }
    this.cdr.markForCheck();
  }

  visibilityUpdate(isVisible) {
    this.tableFilterVisible = isVisible;
    this.cdr.markForCheck();
  }

  resetZIndex(target) {
    // Reset z-index of parent on modal close
    setTimeout(() => {
      if (target?.offsetParent) {
        target.offsetParent.style.zIndex = this.originalZIndexValue;
        this.cdr.markForCheck();
      }
    });
  }

  isClipSaved(clip) {
    return this.savedVideosLookup && (
      clip.synergyEventID && this.savedVideosLookup?.synergyEventIDs?.includes(clip.synergyEventID) ||
      clip.nbaChanceID && this.savedVideosLookup?.nbaChanceIDs?.includes(clip.nbaChanceID) ||
      clip.eagleChanceID && this.savedVideosLookup?.eagleChanceIDs?.includes(clip.eagleChanceID)
    );
  }

  changeToClipAndSave(index) {
    this.onSaveClip.emit(index);
  }

  editRowLabel(clip, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.overlayClip = clip;
    clip.isOverlayVisible = true;
    setTimeout(() => {
      if (this.labelContainer) {
        this.labelContainer.nativeElement.focus();
      }
    }, 50);
  }

  updateClipLabel(clip, label) {
    clip.label = label;
    clip.isOverlayVisible = false;
    this.store$.dispatch(new actions.SaveAction({'videoSaved': clip, 'chainID': this.videoService.generateChainID()}));
    this.cdr.markForCheck();
  }

  dropRow($event) {
    this.onClipDrop.emit($event);
  }

  getActionDescription(actionName) {
    return this.videoHelper.actionDescriptionHelper(actionName);
  }

  ngOnDestroy() {
  }
}
