import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import * as _ from 'lodash';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScenarioDialogComponent } from 'apps/contract/_shared/scenario-dialog/scenario-dialog.component';
import { ConfirmDialogComponent } from '@bild-dialogs/confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';
import { ContractService } from '@services/contract.service';
import { TempTransactionAction } from 'apps/contract/_constants/constants';

enum SCENARIO_FILTERS {
  ACTIVE,
  ALL,
  MINE,
  EDITOR,
  SHARED,
  CONFLICT,
  ARCHIVED,
}

enum SCENARIO_SORTING {
  RECENT,
  ASC,
  DESC,
}

@UntilDestroy()
@Component({
  selector: 'scenario-menu',
  templateUrl: './scenario-menu.component.html',
  styleUrls: ['../nav-menu.component.scss', './scenario-menu.component.scss', '../../primary-toolbar/primary-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class ScenarioMenuComponent implements OnInit {
  _scenarioList: any;
  @Input() set scenarioList(val: any) {
    this._scenarioList = val;
    if (val) {
      this.showConflictFilter = val.data?.some(scenario => scenario.hasConflict);
      this.handleScenarioFilterChange(this.currentFilter);
    }
    this.conflictPlayers = !this.selectedScenarioID ? this.globalConflictPlayers : (this._scenarioList?.data?.find(scenario => scenario.id == this.selectedScenarioID)?.conflictPlayers || []);
  }

  get scenarioList() {
    return this._scenarioList;
  }

  get isLoading() {
    return _.isEmpty(this.scenarioList);
  }

  _selectedScenarioID: number;
  @Input() set selectedScenarioID(val: number) {
    this._selectedScenarioID = val;
    this.conflictPlayers = !this._selectedScenarioID ? this.globalConflictPlayers : (this.scenarioList?.data?.find(scenario => scenario.id == this._selectedScenarioID)?.conflictPlayers || []);
  }

  get selectedScenarioID() {
    return this._selectedScenarioID;
  }

  @Output() scenarioChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('scenarioSearch') scenarioSearch;

  readonly scenarioFilters = SCENARIO_FILTERS;
  readonly scenarioSorts = SCENARIO_SORTING;
  conflictPlayers: any[] = [];
  currentFilter: SCENARIO_FILTERS = SCENARIO_FILTERS.ACTIVE;
  currentSort: SCENARIO_SORTING = SCENARIO_SORTING.RECENT;
  currentUser: any;
  filteredScenarios: any[] = [];
  globalConflictPlayers: any[] = [];
  hoverScenarioID: number;
  isMobile: boolean;
  isSearchActive: boolean = false;
  menuOpenScenarioID: number;
  searchValue = '';
  showConflictFilter: boolean = false;

  scenarioScrollOffset: number = 0;
  @HostListener('scroll', ['$event']) scrollHandler(event) {
    this.scenarioScrollOffset = event.srcElement.scrollTop;
  }

  get filterDisplay() {
    switch (this.currentFilter) {
      case this.scenarioFilters.ACTIVE:
        return 'Active Scenarios';
      case this.scenarioFilters.ALL:
        return 'All Scenarios';
      case this.scenarioFilters.MINE:
        return 'By you';
      case this.scenarioFilters.EDITOR:
        return 'Editor';
      case this.scenarioFilters.SHARED:
        return 'Shared';
      case this.scenarioFilters.CONFLICT:
        return 'Conflicts';
      case this.scenarioFilters.ARCHIVED:
        return 'Archived';
    }
  }

  get sortingHeader() {
    switch (this.currentSort) {
      case this.scenarioSorts.RECENT:
        return 'Recent';
      case this.scenarioSorts.ASC:
        return 'A -> Z';
      case this.scenarioSorts.DESC:
        return 'Z -> A';
    }
  }

  constructor(
    protected authService: AuthService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected contractService: ContractService,
    protected dialog: MatDialog,
  ) {}

  ngOnInit() {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.authService.currentUserData.pipe(untilDestroyed(this)).subscribe((result: any[]) => {
      this.currentUser = result;
    });
  }

  handleScenarioFilterChange(filterValue?) {
    this.searchScenarios();
    this.filterScenarios(filterValue);
    this.sortScenarios();
  }

  filterScenarios(filterValue) {
    this.currentFilter = filterValue;
    switch (this.currentFilter) {
      case this.scenarioFilters.ACTIVE:
        this.filteredScenarios = this.filteredScenarios.filter(scenario => !scenario.isHistorical);
        break;
      case this.scenarioFilters.ALL:
        this.filteredScenarios = this.filteredScenarios;
        break;
      case this.scenarioFilters.MINE:
        this.filteredScenarios = this.filteredScenarios.filter(scenario => scenario.owner.userID == this.currentUser.id);
        break;
      case this.scenarioFilters.EDITOR:
        this.filteredScenarios = this.filteredScenarios.filter(scenario => scenario.isEditor);
        break;
      case this.scenarioFilters.SHARED:
        this.filteredScenarios = this.filteredScenarios.filter(scenario => scenario.owner.userID != this.currentUser.id);
        break;
      case this.scenarioFilters.CONFLICT:
        this.filteredScenarios = this.filteredScenarios.filter(scenario => scenario.hasConflict);
        break;
      case this.scenarioFilters.ARCHIVED:
        this.filteredScenarios = this.filteredScenarios.filter(scenario => scenario.isHistorical);
        break;
      }
    this.cdr.markForCheck();
  }

  searchScenarios() {
    if (!this.scenarioList.data) {
      this.filteredScenarios = [];
    } else if (this.searchValue === '') {
      this.filteredScenarios = _.cloneDeep(this.scenarioList.data);
    } else {
      const searchValue = this.searchValue.toLowerCase();
      this.filteredScenarios = _.filter(this.scenarioList.data, (scenario) => {
        return scenario.name.toLowerCase().includes(searchValue);
      });
    }

    let globalConflict = false;
    this.filteredScenarios.forEach((scenario, i) => {
      if (scenario.isGlobal) {
        globalConflict = true;
        this.globalConflictPlayers = scenario.conflictPlayers;
        this.filteredScenarios.splice(i, 1);
      }
    });
    if (!globalConflict) {
      this.globalConflictPlayers = [];
    }
  }

  toggleSorting() {
    switch (this.currentSort) {
      case this.scenarioSorts.RECENT:
        this.currentSort = this.scenarioSorts.ASC;
        this.sortScenarios();
        break;
      case this.scenarioSorts.ASC:
        this.currentSort = this.scenarioSorts.DESC;
        this.sortScenarios();
        break;
      case this.scenarioSorts.DESC:
        this.currentSort = this.scenarioSorts.RECENT;
        this.sortScenarios();
        break;
    }
  }

  sortScenarios() {
    let ascending;
    if (this.currentSort == this.scenarioSorts.ASC) {
      ascending = true;
    }
    else if (this.currentSort == this.scenarioSorts.DESC) {
      ascending = false;
    }

    if (ascending != null) {
      this.filteredScenarios = _.orderBy(this.filteredScenarios, ['name'], ascending ? 'asc' : 'desc');
    } else {
      this.filteredScenarios = _.orderBy(this.filteredScenarios, ['createDatetime'], 'desc');
    }
  }

  setSearchActive(val, event?) {
    if (val || event.relatedTarget?.classList?.contains('scenario-search')) {
      this.isSearchActive = true;
      setTimeout(() => {
        this.scenarioSearch.nativeElement.focus();
        this.cdr.markForCheck();
      }, 20);
    } else if (this.searchValue == '') {
      this.isSearchActive = false;
    }
  }

  selectScenario(scenarioID: number) {
    this.scenarioChange.emit(scenarioID);
  }

  getConflictPlayerMessage(playerList?: boolean) {
    if (this.conflictPlayers && !_.isEmpty(this.conflictPlayers)) {
      return playerList ? this.conflictPlayers.join('\n') : ['Players with conflicts:'].concat(this.conflictPlayers);
    }
    return null;
  }

  openScenarioDialog($event, scenario?: any, copy?: boolean) {
    $event.stopPropagation();
    let copyScenario;

    if (copy) {
      copyScenario = JSON.parse(JSON.stringify(scenario));
      copyScenario.copyScenarioID = copyScenario.id;
      copyScenario.id = null;
      copyScenario.name = null;
      copyScenario.editDatetime = null;
    }

    const dialogConfig: MatDialogConfig = {
      data: copy? copyScenario : scenario,
      width: '800px',
      panelClass: 'scenario-dialog'
    };
    const dialogRef = this.dialog.open(ScenarioDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(take(1), untilDestroyed(this)).subscribe(result => {
      if (result) {
        this.contractService.sendContractSocketAction(result);
        if (result.scenarioAction == TempTransactionAction.DELETE_SCENARIO) {
          this.scenarioList.data.filter(s => s.id != result.payload.id);
          if (result.payload.id == this.selectedScenarioID) {
            this.selectScenario(null);
          }
        }
        this.handleScenarioFilterChange(this.currentFilter);
      }
    });
  }

  deleteScenario($event, scenario: any) {
    $event.stopPropagation();
    if (scenario.owner.userID != this.currentUser.id) {
      return;
    }

    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Delete Scenario',
        message: 'Are you sure you want to delete the following scenario?\n\n- ' + (scenario.name) +'\n\nThis action cannot be undone.',
        acceptButtonTitle: 'Yes, delete',
        cancelButtonTitle: 'Cancel',
        buttonClass: 'warn',
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(take(1), untilDestroyed(this)).subscribe(result => {
      if (result) {
        this.contractService.sendContractSocketAction({scenarioAction: TempTransactionAction.DELETE_SCENARIO, payload: scenario});
        if (scenario.id == this.selectedScenarioID) {
          this.selectScenario(null);
        }
      }
    });
  }

  archiveScenario($event, scenario: any) {
    $event.stopPropagation();
    if (scenario.owner.userID != this.currentUser.id) {
      return;
    }

    this.contractService.sendContractSocketAction({scenarioAction: TempTransactionAction.ARCHIVE_SCENARIO, payload: scenario});
    this.handleScenarioFilterChange(SCENARIO_FILTERS.ARCHIVED);
  }

  activateScenario($event, scenario: any) {
    $event.stopPropagation();
    if (scenario.owner.userID != this.currentUser.id) {
      return;
    }

    this.contractService.sendContractSocketAction({scenarioAction: TempTransactionAction.ACTIVATE_SCENARIO, payload: scenario});
    this.handleScenarioFilterChange(SCENARIO_FILTERS.ACTIVE);
  }
}
